<ng-container *ngIf="chartData && chartData.datasets">
  <canvas
    baseChart
    [ngClass]="{ 'with-height': minHeight != 100 }"
    [ngStyle]="{ 'min-height': minHeight + 'px' }"
    [type]="'line'"
    [options]="chartOptions"
    [data]="chartData"
    [legend]="false"
  ></canvas>
</ng-container>
