import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FeatureType } from 'src/app/components/featured-plates/featured-plates.component';
import {
  PlateCarConfig,
  PlateViewerConfig,
} from 'src/app/models/plateViewerConfig';
import { AdminService } from 'src/app/services/admin-service';
import { ImageKeyPair, ImageService } from 'src/app/services/image-service';

@Component({
  selector: 'pla-plate-viewer-page',
  templateUrl: './plate-viewer-page.component.html',
  styleUrls: ['./plate-viewer-page.component.scss'],
})
export class PlateViewerPageComponent implements OnInit {
  public raFeatured = new FeatureType('Recently Added', 'recently-added');
  public rsFeatured = new FeatureType('Recently Sold', 'recently-sold');
  public alphabet: string = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  public selectedImage: ImageKeyPair = null;
  public selectedConfig: PlateViewerConfig;
  public registrationString: string = 'PLA 73X';
  public configs: PlateViewerConfig[] = [];
  private quickConfig: PlateViewerConfig;
  public lastAlphabetToView: string = 'A';

  private pageType: string = null;
  private inputString: string = null

  constructor(
    private imageService: ImageService,
    private adminService: AdminService,
    private route: ActivatedRoute
  ) {
    this.pageType = this.route.snapshot.params['type'];
    this.inputString = this.route.snapshot.params['input'];
    const plateIfCar = this.route.snapshot.params['plate'];
    if (this.pageType != null && this.inputString != null) {
      if (this.pageType === 'plate') {
        this.registrationString = this.inputString;
      } else {
        if (plateIfCar !== null) this.registrationString = plateIfCar;
      }
    }
  }

  ngOnInit(): void {
    this.GetConfigs();
    if (this.pageType == null || this.inputString == null || this.pageType != 'car') {
      this.generateQuickImage();
    }
  }

  private ConfigsFetched(): void {
    if (this.pageType != null && this.inputString != null) {
      if (this.pageType === 'car') {
        const toSet = this.configs.find((p) => p.carName.toLowerCase().indexOf(this.inputString.toLowerCase()) > -1);
        if (toSet === undefined) {
          console.log('car to set', toSet);
          return;
        }
        this.updateConfig(toSet);
      }
    }
  }

  public LetterInView(event: { target: HTMLElement, visible: boolean }, letter: any): void {
    if (event.visible) this.lastAlphabetToView = letter;
  }

  private GetConfigs(): void {
    this.adminService
      .getImageConfigGroups() // this is unique groups
      .subscribe((_configs: PlateViewerConfig[]) => {
        _configs = _configs.map((c: PlateViewerConfig) => {
          var _ = new PlateViewerConfig(c);
          return _;
        });

        this.configs = _configs.sort((a, b) =>
          a.carName.localeCompare(b.carName)
        );

        this.ConfigsFetched();
      });
  }

  private generateQuickImage(): void {
    this.quickConfig = this.imageService.getQuickConfig();
    this.imageService.INIT();
    this.imageService.quickBuild(
      this.quickConfig,
      this.registrationString,
      (image: ImageKeyPair) => {
        this.selectedImage = image;
      }
    );
  }

  public ImagesForLetter(letter: string): PlateViewerConfig[] {
    if (this.configs === undefined || this.configs.length === 0) return [];
    return this.configs.filter((p) => p.carName.startsWith(letter));
  }

  public updateConfig(config: PlateViewerConfig): void {
    this.selectedConfig = config;
    this.UpdateImage();
  }

  public UpdateImage(): void {
    if (window.innerWidth > 768) window.scrollTo({ top: 0, behavior: 'smooth' });
    if (this.selectedConfig == null) {
      this.imageService.quickBuild(
        this.quickConfig,
        this.registrationString,
        (image: ImageKeyPair) => {
          this.selectedImage = image;
        }
      );
      return;
    }
    this.imageService.generateImage(
      this.selectedConfig,
      this.registrationString,
      (_: ImageKeyPair) => {
        this.selectedImage = _;
      }
    );
  }
}
