<div class="position-relative tp-sellers">
  <!-- MOBILE FILTER BUTTON -->
  <div *ngIf="resultsCanShow && !loading" [style.display]="mobileFilters ? 'none' : 'block'"
    class="position-sticky start-0 end-0 bg-white shadow-sm align-items-center" style="z-index: 51; top: 92px">
    <div class="d-flex justifty-content-center align-items-stretch flex-row">
      <div class="flex-fill w-100 d-flex d-lg-none border-end">
        <pla-custom-dropdown [config]="sortConfig" (change)="changeSort($event)"
          class="d-block w-100"></pla-custom-dropdown>
      </div>

      <div class="flex-fill w-100 d-flex d-lg-none align-items-center justify-content-center gap-2 py-2 text-dark px-2"
        (click)="ToggleMobileFilters(true)">
        <mat-icon class="small">filter_alt</mat-icon>
        <small class="mb-0 text-center">Filter</small>
      </div>
    </div>
  </div>

  <div class="pt-3 px-3 px-md-1">
    <div class="px-0 px-md-4 mx-auto" *ngIf="loading || !resultsCanShow">
      <div class="mx-auto" style="max-width: 1000px">
        <pla-compare-loader [almostComplete]="searchAlmostComplete" (complete)="CompleteLoad()"></pla-compare-loader>
      </div>
    </div>
    <div class="px-0 px-md-4 mx-auto" *ngIf="!loading && resultsCanShow">
      <div
        class="mx-auto d-none d-lg-flex align-items-center justify-content-between justify-content-md-end gap-3 flex-wrap flex-md-nowrap"
        style="max-width: 1000px">
        <div class="">
          <p class="mb-0">
            {{ viewResults !== undefined ? viewResults.length : "0" }} Results
          </p>
        </div>
        <div class="flex-fill"></div>

        <div style="min-width: 190px" class="bg-white rounded-3 border">
          <!-- SORT -->
          <pla-custom-dropdown [config]="sortConfig" (change)="changeSort($event)"
            class="d-block w-100"></pla-custom-dropdown>
        </div>
      </div>

      <!-- RESULTS -->
      <div class="accordion accordion-flush" id="tp-seller-accordion">
        <ng-container *ngFor="let compareResult of viewResults; let i = index">
          <div *ngIf="i < 9 || (i >= 9 && seeAll)" class="position-relative">
            <!-- DESKTOP -->
            <div
              class="{{
                compareResult.total <= 0 ? 'opacity-25' : ''
              }} d-none d-md-flex justify-content-between align-items-stretch bg-white shadow-sm mb-3 mt-4 px-1 py-2 text-center"
              style="border-radius: 20px">
              <!-- TITLE + TP -->
              <div
                class="border-end border-super-light flex-fill px-3 py-2 d-flex flex-column justify-content-center align-items-center w-100"
                style="min-width: 160px; max-width: 235px">
                <span class="d-block text-dark text-center mb-2 fs-5">{{
                  compareResult.viewName
                  }}</span>
                <div class="mx-auto" (click)="openTPLink(compareResult)">
                  <img style="max-width: 90px" src="{{ trustPilotScoreImage(compareResult) }}"
                    class="d-block mx-auto" />
                  <small class="text-decoration-underline d-block">{{
                    compareResult.trustPilotRatingCount | number : "1.0-0"
                    }}
                    reviews</small>
                </div>
              </div>

              <!-- FINANCE -->
              <div style="max-width: 90px"
                class="border-end border-super-light flex-fill px-1 py-2 d-flex flex-column justify-content-center align-items-center w-100">
                <mat-icon class="{{ iconClasses }}" [style.color]="compareResult.finance ? '#44A43B' : '#C11414'">{{
                  compareResult.finance ? "done" :
                  "close" }}</mat-icon>
                <small class="mt-2">Offers Finance</small>
              </div>

              <!-- DVLA RECOGNISED -->
              <div style="max-width: 90px"
                class="border-end border-super-light flex-fill px-1 py-2 d-flex flex-column justify-content-center align-items-center w-100">
                <mat-icon class="{{ iconClasses }}" [style.color]="true ? '#44A43B' : '#C11414'">{{ true ?
                  "done" : "close" }}</mat-icon>
                <small class="mt-2">DVLA Recognised</small>
              </div>

              <!-- Managed Transfer -->
              <div style="max-width: 90px"
                class="border-end border-super-light flex-fill px-1 py-2 d-flex flex-column justify-content-between align-items-center w-100">
                <mat-icon
                  *ngIf="(compareFilterValues != undefined && compareFilterValues.managedTransfer) || compareResult.transferService == -1"
                  class="{{ iconClasses }}" [style.color]="
                    compareResult.transferService > -1
                        ? '#44A43B'
                        : '#C11414'
                  ">{{
                  compareResult.transferService > -1
                  ? compareFilterValues != undefined &&
                  compareFilterValues.managedTransfer
                  ? "done"
                  : "close"
                  : "close"
                  }}</mat-icon>
                <small class="mt-2"
                  *ngIf="(compareFilterValues == undefined || !compareFilterValues.managedTransfer) && compareResult.transferService > 0">{{
                  compareResult.transferService / 100 | currency : "GBP"
                  }}</small>
                <small class="mt-2"
                  *ngIf="(compareFilterValues == undefined || !compareFilterValues.managedTransfer) && compareResult.transferService == 0">FREE</small>
                <small class="mt-2">Managed Transfer</small>
              </div>

              <!-- STANDARD PLATES -->
              <div style="max-width: 90px"
                class="border-end border-super-light flex-fill px-1 py-2 d-flex flex-column justify-content-between align-items-center w-100">
                <mat-icon
                  *ngIf="(compareFilterValues != undefined && compareFilterValues.standardPlates) || compareResult.physicalPlatePrice == -1"
                  class="{{ iconClasses }}" [style.color]="
                    compareResult.physicalPlatePrice > -1
                        ? '#44A43B'
                        : '#C11414'
                  ">{{
                  compareResult.physicalPlatePrice > -1
                  ? compareFilterValues != undefined &&
                  compareFilterValues.standardPlates
                  ? "done"
                  : "close"
                  : "close"
                  }}</mat-icon>
                <small class="mt-2"
                  *ngIf="(compareFilterValues == undefined || !compareFilterValues.standardPlates) && compareResult.physicalPlatePrice > 0">{{
                  compareResult.physicalPlatePrice / 100 | currency : "GBP"
                  }}</small>
                <small class="mt-2"
                  *ngIf="(compareFilterValues == undefined || !compareFilterValues.standardPlates) && compareResult.physicalPlatePrice == 0">FREE</small>
                <small class="mt-2">Standard Plates</small>
              </div>

              <div
                class="d-block d-xl-flex align-items-center justify-content-center flex-row flex-fill ps-2 w-100 py-2 px-2 gap-3"
                style="min-width: 200px">
                <div>
                  <p class="mb-2 mb-xl-0 fw-bold text-dark fs-5" *ngIf="compareResult.total > 0">
                    {{
                    compareResult.total
                    | currency
                    : "GBP"
                    : "symbol"
                    : (compareResult.total < 10000 ? "" : "1.0-0" ) }} </p>
                      <p class="mb-2 mb-xl-0" *ngIf="compareResult.total <= 0">
                        Unavailable
                      </p>
                </div>
                <div style="
                    background-color: #3791A8;
                    max-width: 150px;
                    cursor: pointer;
                  " class="w-100 rounded-pill py-2 mx-xl-0 mx-auto mt-2 mt-xl-0"
                  (click)="openCompareSiteHandler(compareResult)">
                  <p class="mb-0 text-white text-center">Visit Dealer</p>
                </div>
              </div>
            </div>
            <!-- END DESKTOP -->

            <!-- MOBILE -->
            <div class="{{
                compareResult.total <= 0 ? 'opacity-25' : ''
              }} d-block d-md-none bg-white shadow-sm mb-3 mt-2 p-3 text-center" style="border-radius: 20px">
              <div class="d-flex align-items-center justify-content-between">
                <div>
                  <span class="d-block fw-bold text-dark text-start mb-2 fs-5">{{ compareResult.viewName }}</span>

                  <div class="ms-0 me-auto" (click)="openTPLink(compareResult)">
                    <img style="max-width: 70px" src="{{ trustPilotScoreImage(compareResult) }}"
                      class="d-block ms-0 me-auto" />
                    <!-- <small class="text-decoration-underline d-block"
                      >{{
                        compareResult.trustPilotRatingCount | number : "1.0-0"
                      }}
                      reviews</small
                    > -->
                  </div>
                </div>

                <div class="flex-fill ps-2 w-100 text-end">
                  <p class="fw-bold text-dark fs-5 mb-0" *ngIf="compareResult.total > 0">
                    {{
                    compareResult.total
                    | currency
                    : "GBP"
                    : "symbol"
                    : (compareResult.total < 10000 ? "" : "1.0-0" ) }} </p>
                      <p class="mb-0 fs-5" *ngIf="compareResult.total <= 0">
                        Unavailable
                      </p>
                </div>
              </div>
              <div
                class="justify-content-between align-items-stretch d-flex border-top mt-2 mb-3 border-bottom border-super-light">
                <!-- FINANCE -->
                <div
                  class="border-end border-super-light flex-fill px-1 py-2 d-flex flex-column justify-content-between align-items-center w-100">
                  <!-- <div style="height: 20px"></div> -->
                  <mat-icon class="{{ iconClasses }}" [style.color]="
                      compareResult.finance ? '#44A43B' : '#C11414'
                    ">{{ compareResult.finance ? "done" : "close" }}</mat-icon>
                  <small class="mt-2 fs-xs">Offers Finance</small>
                </div>

                <!-- DVLA RECOGNISED -->
                <div
                  class="border-end border-super-light flex-fill px-1 py-2 d-flex flex-column justify-content-between align-items-center w-100">
                  <!-- <div style="height: 20px"></div> -->
                  <mat-icon class="{{ iconClasses }}" [style.color]="true ? '#44A43B' : '#C11414'">{{
                    true ? "done" : "close" }}</mat-icon>
                  <small class="mt-2 fs-xs">DVLA Recognised</small>
                </div>

                <!-- Managed Transfer -->
                <div
                  class="border-end border-super-light flex-fill px-1 py-2 d-flex flex-column justify-content-between align-items-center w-100">
                  <div>
                    <mat-icon
                      *ngIf="(compareFilterValues != undefined && compareFilterValues.managedTransfer) || compareResult.transferService == -1"
                      class="{{ iconClasses }}" [style.color]="
                    compareResult.transferService > -1
                        ? '#44A43B'
                        : '#C11414'
                  ">{{
                      compareResult.transferService > -1
                      ? compareFilterValues != undefined &&
                      compareFilterValues.managedTransfer
                      ? "done"
                      : "close"
                      : "close"
                      }}</mat-icon>
                  </div>
                  <div>
                    <small class="mt-2"
                      *ngIf="(compareFilterValues == undefined || !compareFilterValues.managedTransfer) && compareResult.transferService > 0">{{
                      compareResult.transferService / 100 | currency : "GBP"
                      }}</small>
                    <small class="mt-2"
                      *ngIf="(compareFilterValues == undefined || !compareFilterValues.managedTransfer) && compareResult.transferService == 0">FREE</small>
                  </div>
                  <small class="mt-2 fs-xs">Managed Transfer</small>
                </div>

                <!-- Standard Plates -->
                <div class="flex-fill px-1 py-2 d-flex flex-column justify-content-between align-items-center w-100">
                  <div>
                    <mat-icon
                      *ngIf="(compareFilterValues != undefined && compareFilterValues.standardPlates) || compareResult.physicalPlatePrice == -1"
                      class="{{ iconClasses }}" [style.color]="
                    compareResult.physicalPlatePrice > -1
                        ? '#44A43B'
                        : '#C11414'
                  ">{{
                      compareResult.physicalPlatePrice > -1
                      ? compareFilterValues != undefined &&
                      compareFilterValues.standardPlates
                      ? "done"
                      : "close"
                      : "close"
                      }}</mat-icon>
                  </div>
                  <div>
                    <small class="mt-2"
                      *ngIf="(compareFilterValues == undefined || !compareFilterValues.standardPlates) && compareResult.physicalPlatePrice > 0">{{
                      compareResult.physicalPlatePrice / 100 | currency : "GBP"
                      }}</small>
                    <small class="mt-2"
                      *ngIf="(compareFilterValues == undefined || !compareFilterValues.standardPlates) && compareResult.physicalPlatePrice == 0">FREE</small>
                  </div>
                  <small class="mt-2 fs-xs">Standard Plates</small>
                </div>
              </div>
              <!-- BOTTOM -->
              <div class="d-flex align-items-center justify-content-between">
                <div class="flex-fill"></div>

                <div style="
                    background-color: #3791A8;
                    cursor: pointer;
                  " class="w-100 rounded-pill py-1 mx-2" (click)="openCompareSiteHandler(compareResult)">
                  <p class="mb-0 text-white text-center">Visit Dealer</p>
                </div>
              </div>
            </div>
            <!-- END MOBILE -->
          </div>
        </ng-container>
      </div>

      <!-- SEE MORE OVERLAY -->
      <div *ngIf="!seeAll && viewResults.length > 9"
        class="position-absolute w-100 gradient-fade start-0 end-0 bottom-0 d-flex align-items-center justify-content-end flex-column"
        style="
          z-index: 50;
          min-height: 100px;
          user-select: none;
          pointer-events: none;
        ">
        <div
          class="border border-dark mx-auto d-flex align-items-center justify-content-center gap-2 bg-white rounded-pill px-3 py-1 shadow-sm mb-3"
          style="
            width: fit-content;
            user-select: auto;
            pointer-events: all;
            cursor: pointer;
          " (click)="SelectAllHandler()">
          <span>See More</span>
          <!-- <mat-icon class="large d-block align-self-end">expand_more</mat-icon> -->
        </div>
      </div>
      <!-- SEE MORE END -->
    </div>
  </div>
</div>

<!-- OVERLAY PANEL -->
<div [style.display]="mobileFilters ? 'flex' : 'none'"
  class="position-fixed bottom-0 start-0 end-0 top-0 bg-overlay align-items-stretch justify-content-between flex-column"
  style="z-index: 1000">
  <div (click)="ToggleMobileFilters(false)" class="flex-fill"></div>
  <!-- FILTER PANEL -->
  <div class="bg-white above-shadow-sm position-fixed bottom-0 start-0 end-0" style="
      border-top-left-radius: 20px !important;
      border-top-right-radius: 20px !important;
    ">
    <!-- FILTERS -->
    <div class="py-3 px-4">
      <div class="d-flex align-items-center justify-content-end text-dark">
        <mat-icon (click)="ToggleMobileFilters(false)">close</mat-icon>
      </div>
      <pla-side-bar-filters [location]="'mobile-popup'" [filters]="compareFilters"></pla-side-bar-filters>
    </div>

    <!-- ACTIONS -->
    <div>
      <div style="background-color: #007850; cursor: pointer" class="w-100 py-3" (click)="ToggleMobileFilters(false)">
        <p class="w-100 mb-0 text-white text-center">
          View
          {{ viewResults !== undefined ? viewResults.length : "0" }} Results
        </p>
      </div>
    </div>
  </div>
</div>