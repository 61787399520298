export const environment = {
  production: false,
  stripe_key:
    'pk_test_51Ijh3dCfbeInu3gtjPFL25XYrQhHjNNI2cofFNmChWJsx023G5RJcAkl9dc0sFdcHBzcOpV8q58s0400QuUYlFAE0077EnIoWJ',
  paypal_client_id:
    'AUj49w7FVgvize6E6WaY_lLYYw8sMgVxixolIWHzE2dAPq-nwQoncML3AUX5SD1tzAhCDvj2eFwNZfO9',
  paypal_live_client_id:
    'AVUwGcHwUCZ7l2xqpA5VNSTgF9UkQ-31Juk8QzCgv3WCQj-QeRIl8Bd946dXreEnlUcBf3ptYvKcncI',
  versionNo: require('../../package.json').version,
  apiProtocol: 'https://',
  platoProtocol: 'https://',
  apiBaseAddress: 'api.platex.co.uk/api',
  platoBaseAddress: 'plato.platex.co.uk',
  platoSearchProtocol: 'https://',
  platoSearchBaseAddress:
    '81ugpzvec7.execute-api.eu-west-2.amazonaws.com/Prod/search',
  local_apiProtocol: 'https://',
  local_platoProtocol: 'http://',
  local_apiBaseAddress: 'localhost:5002/api',
  local_platoBaseAddress: 'localhost:8000',
  checkout_enabled: false,
  latestRelease: '74',

  stripeProducts: {
    platex_commission: '/advert-edit/',
    monthly_listing: 'https://buy.stripe.com/test_14k8At4lg9gUa8U288',
    '3_month_listing': 'https://buy.stripe.com/test_9AQ181aJE8cQ6WI7sv',
    yearly_listing: 'https://buy.stripe.com/test_6oE5ohg3Y8cQgxifZ2',
  },
};
