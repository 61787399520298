import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FeatureType } from 'src/app/components/featured-plates/featured-plates.component';

@Component({
  selector: 'pla-buy-landing',
  templateUrl: './buy-landing.component.html',
  styleUrls: ['./buy-landing.component.scss']
})
export class BuyLandingComponent implements OnInit {

  public plateViewerPlate: string = '';
  public meFeatured = new FeatureType(
    'Most Expensive Plates',
    'most-expensive'
  );

  public cards: any[] = [
    { image: 'https://cdn.platex.co.uk/pages/landing/needle-haystack.png', number: '01', title: 'SEARCH ANYTHING', header: `It's easy when you know how`, body: `Industry leading smart search scours the whole market in seconds.`, link: 'Find your needle', url: '/buy' },
    { image: 'https://cdn.platex.co.uk/pages/compare/Compare%20Table.png', number: '02', title: 'FIND THE BEST DEAL', header: `See all deals and save`, body: 'Compare private plate deals from 40+ dealers and save £££', link: 'Plate Compare', url: '/compare' },
    { image: 'https://cdn.platex.co.uk/pages/landing/landing_checkout.png', number: '03', title: 'CHECKOUT SECURELY', header: `Checkout directly with the dealer`, body: 'Choose the extras you wish such as perspex plates or to split payments with finance', link: 'Get Started', url: '/buy' },
    // { image: 'https://cdn.platex.co.uk/pages/landing/price-comparison.png', title: 'PRICE COMPARISON', header: `See all deals and save`, body: 'Compare private plate deals from 40+ dealers and save £££', link: 'Plate Compare', url: '/compare' },
    // { image: 'https://cdn.platex.co.uk/pages/landing/urus-plate.png', title: 'SELL YOUR PLATE', header: `Commission Free Sales`, body: 'Our advanced search ensures your registration is seen by the right buyers more often.', link: 'Get it listed', url: '/sell' },
  ]

  constructor(private router: Router) { }

  ngOnInit(): void {
  }

  public OpenPlateViewerWithCar(car: string): void {
    if (this.plateViewerPlate != null) this.router.navigate([`/plate-viewer/car/${car}/${this.plateViewerPlate}`]);
    else  this.router.navigate([`/plate-viewer/car/${car}`]);
  }

  public OpenPlateViewerWithPlate(): void {
    this.router.navigate([`/plate-viewer/plate/${this.plateViewerPlate}`]);
  }

}
