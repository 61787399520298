import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import $ from 'jquery';
declare global {
  interface JQuery {
    marquee(config: any): void;
  }
}

@Component({
  selector: 'pla-compare-loader',
  templateUrl: './compare-loader.component.html',
  styleUrls: ['./compare-loader.component.scss'],
})
export class CompareLoaderComponent implements OnInit {
  @Input() public almostComplete = new EventEmitter<void>();
  @Output() public complete = new EventEmitter<void>();
  public progress = 0;
  public mainLoader: boolean = true;
  public intervalClock = -1;
  public endClock = -1;
  public siteNames: string[] = [
    'RegTransfers',
    'DVLA',
    'CarReg',
    'Plates4Less',
    'AbsoluteReg',
  ];
  public loadingTexts: string[] = [
    'Verifying registration details...',
    'Checking DVLA records...',
    'Looking up sales history...',
    'Checking prices from multiple dealers and online sources...'
  ]

  constructor() { }

  ngOnInit(): void {
    this.Start();

    this.almostComplete.subscribe(() => {
      this.mainLoader = false;

      this.endClock = window.setInterval(() => {
        if (this.progress < 90) {
          this.progress = 90;
        }
        if (this.progress >= 100) {
          window.clearInterval(this.endClock);
          setTimeout(() => {
            this.mainLoader = true;
            this.complete.emit();
          }, 750);
          return;
        }

        this.progress += 1;
      }, 100);
    });
  }

  public Start(): void {
    this.intervalClock = window.setInterval(() => {
      if (this.progress >= 90) {
        window.clearInterval(this.intervalClock);
        return;
      }
      this.progress += .3;
    }, 32);
  }
}
