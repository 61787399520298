<div *ngIf="filtersConfig">
  <ng-container *ngFor="let filterGroup of visibleFilters(); last as isLast">
    <div class="{{ isLast ? '' : 'mb-4' }}">
      <p class="fw-bolder fs-md-16">{{ filterGroup.viewName }}</p>
      <ng-container *ngFor="let filter of filterGroup.filters">

        <ng-template *ngIf="filter.filterType == 'checkbox'" [ngTemplateOutlet]="checkbox"
          [ngTemplateOutletContext]="{ filter: filter }"></ng-template>
        <ng-template *ngIf="filter.filterType == 'select'" [ngTemplateOutlet]="select"
          [ngTemplateOutletContext]="{ filter: filter }"></ng-template>
      </ng-container>
    </div>
  </ng-container>
</div>

<ng-template #checkbox let-filter="filter">
  <mat-checkbox [color]="'primary'" class="w-100 overflow-hidden" (change)="ValChange($event, filter.key)"><span
      class="fs-6 text-decoration-none text-dark d-block my-0">{{ filter.viewName }}</span></mat-checkbox>
</ng-template>

<ng-template #select let-filter="filter">
  <pla-custom-dropdown [baseClass]="'bg-white border-1 border-dark'" [closedClass]="'rounded-3 border'"
    [openedClass]="'rounded-top-3 border-start border-top border-end'" [optionClass]="'border-start border-end border-bottom rounded-bottom-3'"
    (change)="SelectChange($event, filter.key)" [config]="filter.config.selectConfig"></pla-custom-dropdown>
</ng-template>