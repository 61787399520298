import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

export class DropdownConfig {
  constructor(public title: string, public icon: string, public options: DropdownOption[] = []) { }
}

export class DropdownOption {
  constructor(public value: string, public view: string) { }
}

@Component({
  selector: 'pla-custom-dropdown',
  templateUrl: './custom-dropdown.component.html',
  styleUrls: ['./custom-dropdown.component.scss'],
})
export class CustomDropdownComponent implements OnInit {
  @Input() public baseClass: string;
  @Input() public openedClass: string;
  @Input() public closedClass: string;
  @Input() public optionClass: string;
  @Input() public config: DropdownConfig;
  @Output() public change = new EventEmitter<DropdownOption>();
  public selectedValue: DropdownOption;
  public open: boolean;

  constructor() { }

  ngOnInit(): void {
    this.Init();
  }

  private Init(): void {
    if (this.config == undefined) return;
    if (this.config.options.length == 0) return;
    this.selectedValue = this.config.options[0];
    this.change.emit(this.config.options[0]);
  }

  public ToggleOpen(): void {
    this.open = !this.open;
  }

  public SelectValue(option: DropdownOption): void {
    this.open = false;
    if (this.selectedValue == option) return;
    this.selectedValue = option;
    this.change.emit(option);
  }

  public VisibleOptions(options: DropdownOption[]): DropdownOption[] {
    return options.filter(o => this.selectedValue.value != o.value);
  }
}
