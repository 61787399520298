import { Component, Input, OnInit } from '@angular/core';
import { Registration } from 'src/app/models/registration';
import { QuickSignupService } from 'src/app/services/quick-signup-service';
import { UserService } from 'src/app/services/user-service';

@Component({
  selector: 'pla-inline-favourite',
  templateUrl: './inline-favourite.component.html',
  styleUrls: ['./inline-favourite.component.scss']
})
export class InlineFavouriteComponent implements OnInit {

  public loading: boolean = false;
  @Input() public plate: Registration;
  constructor(private signupService: QuickSignupService, private userService: UserService) { }

  ngOnInit(): void {
  }

  public handleFavouriteRegistration(): void {

    this.signupService.quickSignup(`favourite ${this.plate.formattedRegistration}`, (status: boolean) => {
      if (status) this.favouriteRegistration();
    }, () => { });
  }

  public favouriteRegistration(): void {
    var reg = this.plate;
    reg.favourite = !reg.favourite; // UI update
    this.loading = true;
    this.userService.favouriteRegistration(reg, (favs: Registration[]) => {
      var _filteredFavourites = favs.filter(
        (f) =>
          f.registration.replace(' ', '') == reg.registration.replace(' ', '')
      );
      reg.favourite = _filteredFavourites && _filteredFavourites.length > 0;
      this.loading = false;
    });
  }

}
