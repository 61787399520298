<!-- SPECIAL CONTENT -->
<div
  class="floating-banner-content {{ floating ? '' : 'fixed' }}"
  [style.--activecolour]="backgroundColour"
>
  <div class="page-toggles-container">
    <div [ngClass]="{ bottomBorder: showBorder }" class="flex-space"></div>
    <ng-container *ngFor="let section of sections; let i = index">
      <div
        class="toggle-item"
        [ngClass]="{
          active: activeSection() == section,
          bottomBorder: showBorder
        }"
        (click)="setActiveTabbedSection(i)"
      >
        <h4>{{ section.title }}</h4>
      </div>
    </ng-container>
    <div [ngClass]="{ bottomBorder: showBorder }" class="flex-space"></div>
  </div>
</div>
