import { CurrencyPipe } from '@angular/common';
import {
  Component,
  ContentChild,
  Directive,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { Router } from '@angular/router';
import { RegistrationService } from 'src/app/services/registration-service';
import { SearchReqResponse } from 'src/app/services/search-service';
import { Registration } from '../../models/registration';
import { SearchFilters } from 'src/app/pages/search-results-page/search-results-page.component';
import { environment } from 'src/environments/environment';

export enum ResultType {
  Regular,
  Term,
}

class FilterOption {
  constructor(
    public name: string,
    public viewName: string,
    public value: boolean
  ) { }
}

class Filter {
  constructor(
    public name: string,
    public viewName: string,
    public options: FilterOption[]
  ) { }
}

@Directive({ selector: 'headerDirective' })
export class HeaderDirective {
  constructor() { }
}

@Component({
  selector: 'pla-search-result-table',
  templateUrl: './search-result-table.component.html',
  styleUrls: ['./search-result-table.component.css'],
})
export class SearchResultTableComponent implements OnInit {
  @ViewChild('cont', { static: false }) public componentCont: ElementRef;
  @Input() public searchTableType: string = null;
  @Input() public customHeader: TemplateRef<any>;
  @Input() public customHeaderContent: boolean = false;
  @Input() public headerInFooter: boolean = false;
  @Input() public headerText: string;
  @Input() public searchTitle: string;
  @Input() public resultType: string = 'match';
  @Input() public pluralResultType: string = 'matches';
  @Input() public searchType: string = 'close';
  @Input() public results: SearchReqResponse;
  @Input() public resultChange: EventEmitter<SearchReqResponse>;
  @Input() public filtersChange: EventEmitter<SearchFilters>;
  @Input() public searching: boolean = true;
  @Input() public paginationPageSize: number = 20;
  @Input() public showPagination: boolean = true;
  @Input() public showFilter: boolean = true;
  @Input() public showSort: boolean = true;
  @Input() public showToggle: boolean = false;
  @Input() public showFullSeries: boolean = true;
  @Input() public customButton: EventEmitter<any>;
  @Input() public paginationChange: EventEmitter<any>;
  @Input() public scrollToTopTrigger: EventEmitter<void>;
  @Input() public resultRowType: ResultType = ResultType.Regular;
  @Input() public hideOnEmpty: boolean = false;
  @Input() public showRatings: boolean = false;
  @Input() public topResultSet: boolean = true;
  @Input() public allowShowMore: boolean = false;
  @Input() public showTerm: boolean = false;
  @Input() public whitePlate: boolean = false;
  @Input() public scrollToTopOnPage: boolean = true;
  @Input() public sortOnStart: boolean = true;
  public standardRowType: ResultType = ResultType.Regular;

  public viewResults: Registration[];
  public displayResults: Registration[];
  private filteredResults: Registration[];
  public skeletonItems: number[] = [1, 1, 1, 1, 1, 1, 1, 1, 1, 1];

  private currentPageIndex = 0;

  public filters: Filter[] = [
    new Filter('style', 'Plate Style', [
      new FilterOption('prefix', 'Prefix', true),
      new FilterOption('suffix', 'Suffix', true),
      new FilterOption('current', 'Current', true),
      new FilterOption('dateless', 'Dateless', true),
      new FilterOption('ni', 'Northern Ireland', true),
    ]),
  ];
  public sortMethod: string = 'most-relevant';

  constructor(
    private router: Router,
    private currencyPipe: CurrencyPipe,
    private registrationService: RegistrationService
  ) { }

  public shouldShow(): boolean {
    // if not searching and we have some result array and the result array is empty and the config is set to hide
    if (
      !this.searching &&
      this.results &&
      this.results.registrations &&
      this.results.registrations.length == 0 &&
      this.hideOnEmpty
    )
      return false;
    return true;
  }

  ngOnInit(): void {
    if (this.filtersChange) this.filtersChange.subscribe((_) => this.FilterResults(_));
    if (this.resultChange != null) {
      this.resultChange.subscribe((_: SearchReqResponse) => {
        this.searching = false;
        this.results = _;
        this.resultUpdate();
      });
    }
    if (this.scrollToTopTrigger != null) {
      this.scrollToTopTrigger.subscribe(() => {
        this.ScrollToTop();
      });
    }
    this.resultUpdate();
  }

  public FilterResults(filters: SearchFilters): void {
    // filters
    const availableResults = [...this.results.registrations];
    var newFilteredResults: Registration[] = [];

    if (filters.up_to_350 || filters.from_350_to_750 || filters.over_750) {
      if (filters.up_to_350) newFilteredResults.push(...availableResults.filter((r) => r.price > 0 && r.price <= 35000))
      if (filters.from_350_to_750) newFilteredResults.push(...availableResults.filter((r) => r.price >= 35000 && r.price <= 75000))
      if (filters.over_750) newFilteredResults.push(...availableResults.filter((r) => r.price >= 75000))
    } else {
      newFilteredResults = availableResults;
    }

    // sale type
    if (filters.new_issue || filters.resale || filters.auction || filters.private_listing) {
      newFilteredResults = newFilteredResults.filter((r: Registration) => {
        if (filters.new_issue)
          return (r.plateOwner.toString() == '0')

        if (filters.new_release)
          return (r.plateOwner.toString() == '0' && r.style === 'current' && r.registration.substring(2, 4) == environment.latestRelease);

        if (filters.resale)
          return (r.plateOwner.toString() == '3') // third party sale

        if (filters.auction)
          return (r.plateOwner.toString() == '5') // DVLA auction

        if (filters.private_listing)
          return (r.plateOwner.toString() == '1') // platex listing
      });
    }

    // plate type
    if (filters.current || filters.prefix || filters.suffix || filters.dateless) {
      newFilteredResults = newFilteredResults.filter((r: Registration) => {
        if (filters.current)
          return (r.style == 'current')
        if (filters.prefix)
          return (r.style == 'prefix')
        if (filters.suffix)
          return (r.style == 'suffix')
        if (filters.dateless)
          return (r.style == 'dateless')
      });
    }

    this.filteredResults = newFilteredResults;

    // sorting
    this.sortResults(filters.sortBy || 'most-relevant'); // sort (always set)
    this.gotoPage(0);
  }

  public resultUpdate(): void {
    console.log(this.searchTableType, this.results);
    try {
      if (!this.results || !this.results.registrations) return;
      if (
        this.results.registrations.length <
        this.paginationPageSize * this.currentPageIndex
      )
        this.currentPageIndex = 0;

      this.viewResults = this.results.registrations;
      this.filteredResults = this.viewResults;
      if (this.sortOnStart) this.sortResults(this.sortMethod);
      this.displayResults = this.get_page_range(0, this.paginationPageSize);
      if (this.displayResults.length < this.paginationPageSize)
        this.showPagination = false;
      else this.showPagination = true;

      if (this.sortOnStart) this.filterResults();
    } catch (exception: any) {
      console.warn('resultUpdate()', exception);
    }
  }

  private ScrollToTop(): void {
    // scroll to top of this component
    setTimeout(() => {
      this.componentCont.nativeElement.scrollIntoView({ behaviour: 'smooth' });
    }, 10);
  }

  public paginate(page: PageEvent): void {
    this.gotoPage(page.pageIndex);
    if (this.paginationChange != null) this.paginationChange.emit(page);
    if (this.scrollToTopOnPage) this.ScrollToTop();
  }

  private gotoPage(pageIndex: number) {
    this.currentPageIndex = pageIndex;
    this.displayResults = this.get_page_range(
      pageIndex,
      this.paginationPageSize
    );
  }

  public get_page_range(page_index, page_size): Registration[] {
    var min_data_range = page_index * page_size;
    var max_data_range = (page_index + 1) * page_size;
    max_data_range =
      this.filteredResults.length > max_data_range
        ? max_data_range
        : this.filteredResults.length;
    if (this.viewResults == undefined) return this.viewResults;
    const visibleResults = this.viewResults.slice(
      min_data_range,
      max_data_range
    );

    // track registrations
    if (this.searchTableType == 'main') {
      this.registrationService.trackRegistrations(
        this.results.id,
        visibleResults
      );
    }

    return visibleResults;
  }

  // PLATE INTERACTION

  public toggle(
    element: HTMLElement,
    expand: HTMLElement,
    collapse: HTMLElement
  ) {
    element.classList.toggle('show');
    expand.classList.toggle('hidden');
    collapse.classList.toggle('hidden');
  }

  // TABLE FILTERS

  public filterResults() {
    this.filteredResults = this.results.registrations;
    this.filters.forEach((filter, _) => {
      if (this.showToggle) {
        if (!this.showFullSeries) {
          if (this.filteredResults !== undefined) {
            this.filteredResults = this.filteredResults.filter(
              (r: Registration) => {
                return r.available;
              }
            );
          }
        } else {
          if (this.filteredResults !== undefined) {
            this.filteredResults = this.filteredResults.sort(
              (r1: Registration, r2: Registration) => {
                return r1.available == r2.available ? 0 : r1.available ? -1 : 1;
              }
            );
          }
        }
      } else {
        this.filteredResults = this.filteredResults.filter((r) => {
          let filterActive = false;
          filter.options.forEach((option, _) => {
            if (!filterActive) {
              if (filter && r[filter.name] == null) filterActive = true;
              else filterActive = r[filter.name] == option.name && option.value;
            }
          });
          return filterActive;
        });
      }
    });
    this.sortResults(this.sortMethod);
    this.gotoPage(0);
  }

  public showingCheapest(reg: Registration): boolean {
    return reg.priceBreakdown.total == reg.startingFrom / 100;
  }

  public showStartingFrom(reg: Registration): boolean {
    return reg.startingFrom != 0;
  }

  public sortResults(method: string) {
    this.sortMethod = method;

    this.viewResults = this.sortRegularRows(method);
    this.displayResults = this.get_page_range(
      this.currentPageIndex,
      this.paginationPageSize
    );
  }

  private sortRegularRows(method: string): Registration[] {
    if (this.filteredResults == undefined) return this.filteredResults;
    switch (method) {
      case 'price-ascending':
        return this.filteredResults.sort((a, b) =>
          a.price < b.price ? -1 : 1
        );
      case 'price-descending':
        return this.filteredResults.sort((a, b) =>
          a.price > b.price ? -1 : 1
        );
      case 'least-relevant':
        return this.filteredResults.sort((a, b) =>
          a.index > b.index ? -1 : 1
        );
      default:
        return this.filteredResults.sort((a, b) =>
          a.index < b.index ? -1 : 1
        );
    }
  }

  private sortTermRows(method: string): Registration[] {
    switch (method) {
      case 'price-ascending':
        return this.filteredResults.sort((a, b) =>
          a.price < b.price ? -1 : 1
        );
      case 'price-descending':
        return this.filteredResults.sort((a, b) =>
          a.price > b.price ? -1 : 1
        );
      case 'least-relevant':
        return this.filteredResults.sort((a, b) =>
          a.percentage > b.percentage ? -1 : 1
        );
      default:
        return this.filteredResults.sort((a, b) =>
          a.percentage < b.percentage ? -1 : 1
        );
    }
  }
}
