import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import BlogPost from '../models/blogPost';
import { BlogService } from '../services/blog-service';
import Swiper, { SwiperOptions } from 'swiper';

export class PlatexListing {
  public registration: string;
  public tag: string;
  public price: number;
  public image: string;
}

@Component({
  selector: 'pla-featured-listings',
  templateUrl: './featured-listings.component.html',
  styleUrls: ['./featured-listings.component.scss']
})
export class FeaturedListingsComponent implements OnInit {

  @Input() public listings: PlatexListing[] = [
    { "registration": "J4 MES", "tag": "New", "price": 50000, "image": "https://cdn.platex.co.uk/listing-images/paradox_listing.png" },
    { "registration": "PA24 DOX", "tag": "Boosted", "price": 79900, "image": "https://cdn.platex.co.uk/listing-images/paradox_listing.png" },
    { "registration": "JU54 LJS", "tag": "Great Value", "price": 29900, "image": "https://cdn.platex.co.uk/listing-images/paradox_listing.png" },
    { "registration": "JU54 LJS", "tag": "Great Value", "price": 29900, "image": "https://cdn.platex.co.uk/listing-images/paradox_listing.png" },
    { "registration": "JU54 LJS", "tag": "Great Value", "price": 29900, "image": "https://cdn.platex.co.uk/listing-images/paradox_listing.png" },
    { "registration": "JU54 LJS", "tag": "Great Value", "price": 29900, "image": "https://cdn.platex.co.uk/listing-images/paradox_listing.png" },
  ];
  public swiperNav: { start: boolean; end: boolean } = {
    start: true,
    end: false,
  };

  public swiperNavigation = {
    nextEl: '.swiper-nav.swiper-next',
    prevEl: '.swiper-nav.swiper-prev',
  };

  public swiperConfig: SwiperOptions = {
    slidesPerView: 1,
    pagination: false,
    loop: false,
    loopFillGroupWithBlank: true,
    navigation: this.swiperNavigation,
    enabled: true,
    spaceBetween: 10,
    setWrapperSize: true,
    centeredSlides: false,
    on: {
      slideChange: (swiper: Swiper) => {
        this.swiperNav = { start: swiper.isBeginning, end: swiper.isEnd };
        this.changeDetection.detectChanges();
      },
    },
    breakpoints: {
      0: {
        slidesPerView: 1.7,
        spaceBetween: 10,
        centeredSlides: false,
      },
      480: {
        slidesPerView: 2,
        spaceBetween: 10,
        centeredSlides: false,
      },
      570: {
        slidesPerView: 2.5,
        spaceBetween: 10,
        centeredSlides: false,
      },
      768: {
        slidesPerView: 3,
        spaceBetween: 10,
        centeredSlides: false,
      },
      950: {
        slidesPerView: 4,
        spaceBetween: 10,
      },
      1260: {
        slidesPerView: 4.5,
        spaceBetween: 20,
      },
      1400: {
        slidesPerView: 4.5,
        spaceBetween: 40,
      },
    },
  };

  constructor(private blogService: BlogService,
    private changeDetection: ChangeDetectorRef) {
  }

  ngOnInit(): void {
    // if (this.blogPosts.length != 0) return;
    // this.blogService.GetArticles().subscribe((posts: BlogPost[]) => {
    //   this.blogPosts = posts;
    // });
  }

  public tagColour(listing: PlatexListing): string {
    if (listing.tag === 'New') return '#007850';
    if (listing.tag === 'Boosted') return '#860202';
    if (listing.tag === 'Great Value') return '#3791A8';
    return '#860202';
  }

}
