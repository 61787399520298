import { Component, Input, OnInit } from '@angular/core';

export class InfoDropdownContent {
  constructor(public title: string, public content: string) {}
}

export class InfoDropdown {
  constructor(
    public id: string,
    public title: string,
    public open: boolean = false,
    public content: InfoDropdownContent[] = []
  ) {}
}

@Component({
  selector: 'pla-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss'],
})
export class FaqComponent implements OnInit {
  @Input() public faq: InfoDropdown[] = [];

  constructor() {}

  ngOnInit(): void {}
}
