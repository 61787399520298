import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'pla-text-changer',
  templateUrl: './text-changer.component.html',
  styleUrls: ['./text-changer.component.scss']
})
export class TextChangerComponent implements OnInit {

  @Input() public texts: string[] = [];
  @Input() public transition: 'fade';
  @Input() public duration: number = 2;

  constructor() { }

  ngOnInit(): void {
  }

}
