import { Component, EventEmitter, Input, OnInit } from '@angular/core';
import { PlateConfigOptions } from '../plate/plate.component';

@Component({
  selector: 'pla-plate-option-selection',
  templateUrl: './plate-option-selection.component.html',
  styleUrls: ['./plate-option-selection.component.scss'],
})
export class PlateOptionSelectionComponent implements OnInit {
  seletedTab: number = 1;
  sizeOptions: any;
  selectedSize: any = null;

  fontOptions: any;
  selectedFont: any = null;

  borderOptions: any;
  selectedBorder: any = null;

  flagOptions: any;
  selectedFlag: any = null;

  @Input('registration') public registration: string;
  @Input('optionsChanged')
  public optionsChanged: EventEmitter<PlateConfigOptions>;
  public redrawPlate: EventEmitter<PlateConfigOptions> =
    new EventEmitter<PlateConfigOptions>();

  constructor() {}

  ngOnInit(): void {
    this.sizeOptions = [
      {
        id: 0,
        key: 'standard',
        name: 'Standard Pair',
        icon: '/assets/customiser/SIZE_3.png',
      },
      {
        id: 1,
        key: 'oblong-square',
        name: 'Oblong / Square',
        icon: '/assets/customiser/SIZE_5.png',
      },
      {
        id: 2,
        key: 'oblong-pair',
        name: 'Oblong / 21" x 6',
        icon: '/assets/customiser/SIZE_4.png',
      },
      {
        id: 3,
        key: 'square-pair',
        name: 'Square Pair',
        icon: '/assets/customiser/SIZE_6.png',
      },
      {
        id: 4,
        key: 'motorbike',
        name: 'Motorbike',
        icon: '/assets/customiser/SIZE_1.png',
      },
      {
        id: 5,
        key: 'special-size',
        name: 'Special Size',
        icon: '/assets/customiser/SIZE.png',
      },
    ];

    this.fontOptions = [
      {
        id: 0,
        key: 'standard',
        name: 'Standard',
        icon: '/assets/customiser/FONT_2.png',
      },
      { id: 1, key: '4d', name: '4D', icon: '/assets/customiser/FONT_2_1.png' },
    ];

    this.borderOptions = [
      {
        id: 0,
        key: 'black',
        name: 'black',
        icon: '/assets/customiser/BLACK-BORDER.png',
        border: 'black',
      },
      {
        id: 1,
        key: 'blue',
        name: 'blue',
        icon: '/assets/customiser/BLUE-BORDER.png',
        border: 'blue',
      },
      {
        id: 2,
        key: 'red',
        name: 'red',
        icon: '/assets/customiser/RED-BORDER.png',
        border: 'red',
      },
      {
        id: 3,
        key: 'none',
        name: 'none',
        icon: '/assets/customiser/NO-BORDER.png',
        border: '0',
      },
    ];

    this.flagOptions = [
      { id: 0, key: 'gb', name: 'GB', icon: '/assets/customiser/GB-FLAG.png' },
      {
        id: 1,
        key: 'eng',
        name: 'ENG',
        icon: '/assets/customiser/ENG-FLAG.png',
      },
      {
        id: 2,
        key: 'sco',
        name: 'SCO',
        icon: '/assets/customiser/SCO-FLAG.png',
      },
      {
        id: 3,
        key: 'cymru',
        name: 'CYMRU',
        icon: '/assets/customiser/CYMRU-FLAG.png',
      },
      { id: 4, key: 'uk', name: 'UK', icon: '/assets/customiser/UK-FLAG.png' },
      {
        id: 5,
        key: 'electric',
        name: 'Electric',
        icon: '/assets/customiser/ELECTRIC-FLAG.png',
      },
      {
        id: 6,
        key: 'no-flag',
        name: 'No Flag',
        icon: '/assets/customiser/NO-FLAG.png',
      },
    ];
  }

  public getCustomData(): PlateConfigOptions {
    return new PlateConfigOptions(
      this.selectedSize?.key,
      this.selectedFont?.key,
      this.selectedBorder?.key,
      this.selectedFlag?.key
    );
  }

  private emitChange(): void {
    this.optionsChanged.emit();
  }

  selectSize(option: any) {
    this.selectedSize = option;
    this.redrawPlate.emit(this.getCustomData());
    this.emitChange();
  }

  selectFont(option: any) {
    this.selectedFont = option;
    this.redrawPlate.emit(this.getCustomData());
    this.emitChange();
  }

  selectBorder(option: any) {
    this.selectedBorder = option;
    this.redrawPlate.emit(this.getCustomData());
    this.emitChange();
  }

  selectFlag(option: any) {
    this.selectedFlag = option;
    this.redrawPlate.emit(this.getCustomData());
    this.emitChange();
  }

  displayTab(tab: number) {
    this.seletedTab = tab;
  }
}
