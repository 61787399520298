<!-- Hugh query page -->
<mat-form-field class="d-block w-100 mt-3">
    <mat-label>Describe what sold results you want to see</mat-label>
    <textarea [(ngModel)]="aiSearchFeature.req" class="d-block w-100" style="resize: none;" rows="2"
        matInput></textarea>
</mat-form-field>
<ng-container>
    <button *ngIf="!aiSearchFeature.loading" (click)="SearchAI()"
        class="platex-button rounded py-2 px-3">Search</button>
    <mat-progress-spinner *ngIf="aiSearchFeature.loading" class="spinner cyan" mode="indeterminate" [color]="'#327b8d'"
        [diameter]="20"></mat-progress-spinner>
</ng-container>
<ng-container *ngIf="aiSearchFeature.res">
    <p class="mt-3">{{aiSearchFeature.res.request}}</p>
    <p class="mt-3">{{aiSearchFeature.res.query}}</p>

    <ng-container *ngIf="aiSearchFeature.res.res == null || aiSearchFeature.res.res.length <= 0">
        <p>No Results</p>
    </ng-container>

    <ng-container *ngIf="aiSearchFeature.res.res.length > 0 && aiSearchFeature.res.res[0]['count']">
        <p>Count: {{ aiSearchFeature.res.res[0]['count'] }}</p>
    </ng-container>

    <ng-container *ngIf="aiSearchFeature.res.res.length > 0 && !aiSearchFeature.res.res[0]['count']">
        <div class="d-flex align-items-center justify-content-start gap-2">
            <mat-icon (click)="getJson()">open_in_new</mat-icon>
            <p class="mt-3">{{aiSearchFeature.res.res.length}} Results</p>
        </div>
        <ng-container *ngFor="let result of aiSearchFeature.res.res">
            <div class="d-flex align-items-center justify-content-start gap-3 mb-3">
                <pla-plate [rawPlate]="result.registration" [openModal]="true" [mobileTiny]="true"
                    [plateSize]="'smaller'" [customClass]="'flat'"></pla-plate>
                <p>{{ result.sold_price / 100 | currency: 'GBP' }}</p>
                <p>{{ result.sold_date | date: 'dd/MM/yy' }}</p>
                <ng-container [ngSwitch]="result.sale_type">
                    <p *ngSwitchCase="0">DVLA AUCTION</p>
                    <p *ngSwitchCase="1">DVLA SALE</p>
                    <p *ngSwitchCase="2">PLATEX SALE</p>
                    <p *ngSwitchCase="3">THIRD PARTY ({{ result.seller }})</p>
                    <p *ngSwitchCase="4">CURRENTLY LISTED</p>
                    <p *ngSwitchCase="5">INITIAL PRICE</p>
                    <p *ngSwitchCase="6">DVLA AUCTION (IMPORTED)</p>
                    <p *ngSwitchCase="7">THIRD PARTY AUCTION ({{result.seller}})</p>
                    <p *ngSwitchCase="8">PRICE CHANGE</p>
                    <p *ngSwitchCase="9">CUSTOM SELLER ({{result.seller}})</p>
                    <p *ngSwitchDefault>UNKNOWN</p>
                </ng-container>
            </div>
        </ng-container>
    </ng-container>
</ng-container>