import { HttpClient } from '@angular/common/http';
import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnInit,
} from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CompareResult } from 'src/app/compare-result';
import { PlateSeller } from 'src/app/models/thirdPartySeller';
import { UserDetails } from 'src/app/models/userDetails';
import { CompareService } from 'src/app/services/compare-service';
import { ListingService } from 'src/app/services/listing-service';
import { QuickSignupService } from 'src/app/services/quick-signup-service';
import { SessionService } from 'src/app/services/session-service';
import { TrackingService } from 'src/app/services/tracking-service';
import { UserService } from 'src/app/services/user-service';

declare global {
  interface Window {
    gtag: any;
    tp: any;
  }
}

@Component({
  selector: 'pla-compare-results-table',
  templateUrl: './compare-results-table.component.html',
  styleUrls: ['./compare-results-table.component.scss'],
})
export class CompareResultsTableComponent implements OnInit, AfterViewInit {
  @Input() public showHeader: boolean = true;
  @Input() public showTitle: boolean = true;
  @Input() public registration: string;
  @Input() public showNotFoundRows: boolean = true;
  @Input() public sellerResults: PlateSeller[] = null;
  @Input() public lastChecked: Date = null;

  public customSignUpTrigger: EventEmitter<void> = new EventEmitter<void>();
  public withPerspex: boolean = false;
  public withTransfer: boolean = false;

  public results: CompareResult[];
  private compareResults: CompareResult[] = [];

  constructor(
    private compareService: CompareService,
    private sessionService: SessionService,
    private userService: UserService,
    private trackingService: TrackingService,
    private listingService: ListingService,
    private quickSignup: QuickSignupService,
    private snackbar: MatSnackBar
  ) {}

  ngOnInit(): void {
    this.Configure();
  }

  ngAfterViewInit(): void {
    this.customSignUpTrigger.emit();
  }

  private Configure(): void {
    if (!this.showHeader) {
      this.withPerspex = false;
      this.withTransfer = false;
    }
    if (this.registration == '' || this.registration == null) return;
    if (this.sellerResults == null) this.Compare();
    else this.ShowLocalResults();
  }

  private ShowLocalResults(): void {
    this.compareResults = this.compareService.mapSellerToCompare(
      this.sellerResults
    );
    this.CreateLists();
  }

  private Compare(): void {
    this.compareService
      .getPlateComparison(this.registration)
      .subscribe((r: CompareResult[]) => {
        this.compareResults = r;

        this.CreateLists();
      });
  }

  private CreateLists(): void {
    var rawResults = JSON.parse(JSON.stringify(this.compareResults));
    var calculatedRows = this.compareService.calculateFoundRows(
      rawResults,
      this.withTransfer,
      this.withPerspex
    );

    var foundCalculatedRows = [...calculatedRows]
      .filter((r) => r.calculated)
      .sort((a, b) => a.total - b.total);

    var notFoundResults = [...calculatedRows].filter(
      (r) => !r.calculated && r.status == 'NOT FOUND'
    );

    this.results = foundCalculatedRows;
    this.results.push(...notFoundResults);
  }

  public optionChangeHandler($event: any): void {
    this.CreateLists();
  }

  public openTrustPilotHandler(result: CompareResult): void {
    window.open(result.trustPilotLink, '_blank');
  }

  public platexMessage(result: CompareResult): void {
    this.quickSignup.quickSignup(
      `Plate Enquiry (${this.registration})`,
      (status: boolean) => {
        if (status) {
          this.listingService
            .MessageListing(this.registration, result.site)
            .subscribe(() => {
              this.snackbar.open('Message sent.', 'Close', { duration: 3000 });
            });
        }
      },
      () => {}
    );
  }

  public openCompareSiteHandler(result: CompareResult): void {
    window.gtag('event', 'page_view', {
      page_title: `Affiliate - ${this.registration} (${result.viewName})`,
    });

    this.trackingService.TrackAffiliateDetail(
      this.registration,
      result.viewName,
      {
        dealer: result,
      }
    );

    if (this.sessionService.isLoggedIn()) {
      this.userService.getUserDetails((userDetails: UserDetails) => {
        const trustpilot_invitation = {
          recipientEmail: userDetails.email,
          recipientName: userDetails.firstName,
          referenceId: `PLA_${this.registration.replace(' ', '')}`,
          source: 'InvitationScript',
        };
        // window.tp('createInvitation', trustpilot_invitation);
      });
    }

    if (result.url.indexOf('?') == -1) {
      window.open(
        `${result.url}?token=${this.sessionService.getToken()}`,
        '_blank'
      );
    } else {
      window.open(
        `${result.url}&token=${this.sessionService.getToken()}`,
        '_blank'
      );
    }
  }

  public trustPilotScoreImageGenerator(result: CompareResult): string {
    var trustPilotRating = Math.round(result.trustPilotScore * 2) / 2;
    return `https://cdn.trustpilot.net/brand-assets/4.1.0/stars/stars-${trustPilotRating}.svg`;
  }

  public canShowRow(result: CompareResult): boolean {
    if (result.calculated) return true;
    if (this.showNotFoundRows) return true;
    return false;
  }
}
