import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'pla-inline-plate-rank',
  templateUrl: './inline-plate-rank.component.html',
  styleUrls: ['./inline-plate-rank.component.scss']
})
export class InlinePlateRankComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }


  public VoteUp(): void { }
  public VoteDown(): void { }

}
