import {
  AfterContentInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  OnInit,
} from '@angular/core';
import { MatAccordionDisplayMode } from '@angular/material/expansion';
import { ActivatedRoute, Router } from '@angular/router';
import { DataPoint } from 'src/app/components/chart/chart.component';
import { SwitchTab } from 'src/app/components/switch-toggle/switch-toggle.component';
import { DVLAVehicleEnquiryResult } from 'src/app/models/dvlaVehicleEnquiryResult';
import { PlateOwner } from 'src/app/models/enums/plateOwner';
import { HttpLoadingResp } from 'src/app/models/httpLoadingResp';
import { PlateDetail } from 'src/app/models/plateDetail';
import { PlatePaymentDetails } from 'src/app/models/platePaymentDetails';
import { PlateViewerConfig } from 'src/app/models/plateViewerConfig';
import { Registration } from 'src/app/models/registration';
import { SoldPlate } from 'src/app/models/soldPlate';
import { ImageKeyPair, ImageService } from 'src/app/services/image-service';
import { RegistrationService } from 'src/app/services/registration-service';
import { TrackingService } from 'src/app/services/tracking-service';
import { UserService } from 'src/app/services/user-service';
import { environment } from 'src/environments/environment';

declare global {
  interface Window {
    gtag: any;
  }
}

@Component({
  selector: 'pla-registration-detail-page',
  templateUrl: './registration-detail-page.component.html',
  styleUrls: ['./registration-detail-page.component.scss'],
})
export class RegistrationDetailPageComponent
  implements OnInit, AfterContentInit
{
  public isDesktopView(): boolean {
    return window.innerWidth > 850;
  }

  // PAGE CONFIG

  public requireDifferentConsecutivePointsForChart = true;
  public minDataPointsForChart = 2;
  public requireDifferentConsecutivePointsForTable = false;
  public minDataPointsForTable = 1;
  public continueCheckout: boolean = false;
  public showCompareResults: boolean = true;

  //
  public dvlaPlate: boolean = true;
  public isThirdPartyPlate: boolean = true;
  public isRegisteredToVehicle: boolean = false;
  public registrationString: string = null;
  public formattedRegistration: string = null;
  public priceRange: number[] = null;
  public quickImage: ImageKeyPair = null;
  public showCompareSellers: boolean = false;
  public requireLogin: boolean = false;

  // PAGE STATE
  public historyOpen: boolean = false;
  public isPageLoaded: boolean = false;
  public selectedImageOption: ImageKeyPair = null;
  public imageOptions: ImageKeyPair[] = [];
  public imageOptionEmitter: EventEmitter<ImageKeyPair[]> = new EventEmitter<
    ImageKeyPair[]
  >();
  public imageSlideCount: number = 0;
  public loadedAllImages: boolean = false;
  public selectionChangeEmitter: EventEmitter<ImageKeyPair> =
    new EventEmitter<ImageKeyPair>();
  public notifiedFetch: boolean = false;
  public notified: boolean = false;

  // fetched data objects
  public registrationMetaData: HttpLoadingResp<PlateDetail> =
    new HttpLoadingResp();
  public registrationPaymentDetails: HttpLoadingResp<PlatePaymentDetails> =
    new HttpLoadingResp();
  public registeredVehicleInfo: HttpLoadingResp<DVLAVehicleEnquiryResult> =
    new HttpLoadingResp();
  public registrationHistory: HttpLoadingResp<SoldPlate[]> =
    new HttpLoadingResp();
  public registrationHistoryPoints: DataPoint[] = [];

  private rsTabs: SwitchTab[] = [new SwitchTab('Plate Info', 'plateinfo')];

  private tpTabs: SwitchTab[] = [
    new SwitchTab('Compare Deals', 'compare'),
    new SwitchTab('Plate Info', 'plateinfo'),
  ];

  private pTabs: SwitchTab[] = [
    new SwitchTab('Compare Deals', 'compare'),
    new SwitchTab('Plate Info', 'plateinfo'),
    // new SwitchTab('Checkout', 'checkout'),
  ];

  public tabs: SwitchTab[] = this.tpTabs;
  public activeSwitch: string = 'compare';
  public priceHistoryTitle: string = 'Price History';
  public priceHistoryIcon: string = 'trending_up';
  public showFullSearch: boolean = false;
  public checkoutEnabled: boolean = environment.checkout_enabled;
  public mobileImageLoadCount: number = 3;
  public pageQuickImage: PlateViewerConfig;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private registrationService: RegistrationService,
    private imageService: ImageService,
    private userService: UserService,
    private changeRef: ChangeDetectorRef,
    private trackingService: TrackingService
  ) {
    this.registrationString = this.activatedRoute.snapshot.params.registration;
    this.showFullSearch =
      this.activatedRoute.snapshot.queryParams['_l'] == 'direct';
    if (this.registrationString == '' || this.registrationString == null)
      this.router.navigate(['/landing']);

    this.registrationString = this.registrationString
      .toUpperCase()
      .replace(' ', '');
    this.userService.getUserNotifies(() => {
      this.notified = this.registrationService.isNotify(
        this.registrationString
      );
      this.notifiedFetch = true;
    });
  }

  public SwitchChange(tab: SwitchTab): void {
    this.activeSwitch = tab.id;
    if (tab.id == 'checkout') {
      this.continueCheckout = true;
    }
  }

  public searchAllClickHandler(): void {
    this.router.navigate([`/search-results/${this.registrationString}`]);
  }

  ngOnInit(): void {
    this.generateQuickImage();
    this.fetchRegistrationDetails();
    this.loadedAllImages = window.innerWidth > 768;

    this.trackingService.TrackPlateDetail(
      this.registrationString,
      this.showFullSearch
    );
  }

  ngAfterContentInit(): void {}

  private pageLoaded(): void {
    this.isPageLoaded = true;
    this.loadImageOptions(0);
  }

  private generateQuickImage(): void {
    this.pageQuickImage = this.imageService.getQuickConfig();
    this.imageService.INIT();
    this.formattedRegistration =
      this.registrationService.LocalFormatRegistration(this.registrationString);
    if (this.formattedRegistration == this.registrationString)
      this.router.navigate(['/landing']);
    this.imageService.quickBuild(
      this.pageQuickImage,
      this.formattedRegistration,
      (image: ImageKeyPair) => {
        this.quickImage = image;
        this.selectedImageOption = image;
        if (this.imageOptions.find((io) => io.key == image.key) !== undefined)
          return; // dont duplicate!
        this.imageOptions.push(image);
        this.imageOptionEmitter.emit(this.imageOptions);
      }
    );
  }

  private loadImageOptions(startIndex: number = 0): void {
    // TODO: Loading images on page
    this.imageService.whenReady(() => {
      this.imageService.generateImageForAllConfigs(
        this.pageQuickImage,
        this.formattedRegistration,
        startIndex,
        this.loadedAllImages ? -1 : startIndex + this.mobileImageLoadCount,
        (_: ImageKeyPair[]) => {
          _ = _.map((__) => {
            if (this.imageOptions.find((io) => io.key == __.key) === undefined)
              return __; // dont duplicate!
          }).filter((_) => _ != null);

          this.imageOptions.push(..._);
          this.imageOptionEmitter.emit(this.imageOptions);
          this.imageSlideCount = startIndex + this.mobileImageLoadCount;
        }
      );
    });
  }

  public imageOptionChange(value: ImageKeyPair): void {
    this.selectedImageOption = value;
    this.changeRef.detectChanges();

    if (this.loadedAllImages || this.imageSlideCount === 0) return; // default image

    // load more images
    const currSlide = this.imageOptions.findIndex(
      (io) => io.imageId == value.imageId
    );
    if (currSlide + 1 >= this.imageSlideCount) {
      this.loadImageOptions(this.imageSlideCount);
    }
  }

  public imageCompareFn(val1: ImageKeyPair, val2: ImageKeyPair) {
    if (val1 == null || val2 == null) return false;
    return val1.imageId == val2.imageId;
  }

  private fetchRegistrationDetails(): void {
    // fetch required details, first fetching plate information
    this.getPlateMetaData(() => {
      if (
        this.registrationMetaData.data.owner != PlateOwner.DVLA &&
        this.registrationMetaData.data.owner != PlateOwner.DVLA_PENDING &&
        this.registrationMetaData.data.owner != PlateOwner.CURRENT_DVLA_AUCTION
      ) {
        this.fetchRegisteredVehicleInfo();
      }

      if (this.registrationMetaData.data.owner == PlateOwner.NONE) {
        this.fetchRegistrationHistory();
        // mark loading as false
        this.registrationPaymentDetails.Update(false, null);
      } else {
        this.isThirdPartyPlate
          ? this.fetch3rdPartyInformation()
          : this.fetchPlatexInformation();
      }

      this.combinedFormatting();
    });
  }

  private combinedFormatting(): void {
    if (
      this.registrationMetaData.data.owner == PlateOwner.DVLA ||
      this.registrationMetaData.data.owner == PlateOwner.CURRENT_DVLA_AUCTION
    ) {
      // this.priceHistoryTitle = 'New Issue Government Stock';
      // this.priceHistoryIcon = 'new_releases';
      this.dvlaPlate = true;
      this.registrationHistory.Update(false, null); //no history for DVLA
    } else {
      this.dvlaPlate = false;
    }
  }

  private fetchRegisteredVehicleInfo(): void {
    this.registrationService.getVehicleInfo(this.registrationString).subscribe(
      (_: DVLAVehicleEnquiryResult) => {
        this.registeredVehicleInfo.Update(false, _);
        this.isRegisteredToVehicle = _ != null;
      },
      () => {
        this.registeredVehicleInfo.Update(false, null);
      }
    );
  }

  private fetch3rdPartyInformation(): void {
    this.showCompareSellers = true;
    this.registrationService
      .getPlatePaymentDetails(this.registrationString)
      .subscribe((paymentDetails: PlatePaymentDetails) => {
        if (paymentDetails != null) {
          console.log(paymentDetails);
          this.registrationPaymentDetails.Update(
            false,
            this.registrationService.formatPaymentDetails(paymentDetails)
          );
        } else {
          this.registrationPaymentDetails.Update(false, null);
        }

        this.fetchRegistrationHistory();
        this.priceRange = this.getPriceRange();
      });
  }

  private fetchRegistrationHistory(): void {
    this.requireLogin = false;
    this.registrationService.getPlateHistory(
      this.registrationString,
      (history: SoldPlate[]) => {
        if (history != null) {
          history.map((p) => (p.price = Number.parseInt(p.sold_price) / 100));
          this.registrationHistory.Update(false, history);

          // map data to chart points
          this.registrationHistory.data.map((soldPlate: SoldPlate) => {
            var dataPoint = new DataPoint(
              soldPlate.sold_date,
              Number.parseInt(soldPlate.sold_price) / 100
            );
            this.registrationHistoryPoints.push(dataPoint);
          });
        }

        this.pageLoaded();
      },
      (error: any) => {
        this.registrationHistory.Update(false, null);
        if (error == 401) this.requireLogin = true;
      }
    );
  }

  private fetchPlatexInformation(): void {
    this.registrationService
      .getPlatePaymentDetails(this.registrationString)
      .subscribe((paymentDetails: PlatePaymentDetails) => {
        if (paymentDetails != null) {
          this.registrationPaymentDetails.Update(
            false,
            this.registrationService.formatPaymentDetails(paymentDetails)
          );
        } else {
          this.registrationPaymentDetails.Update(false, null);
        }

        this.priceRange = this.getPriceRange();
        this.pageLoaded();
      });
  }

  private getPlateMetaData(callback: () => void): void {
    this.registrationService
      .getPlateDetail(this.registrationString)
      .subscribe((metaData: PlateDetail) => {
        this.registrationMetaData.Update(false, metaData);
        this.isThirdPartyPlate =
          true ||
          (this.registrationMetaData.data.owner != PlateOwner.DVLA &&
            this.registrationMetaData.data.owner != PlateOwner.PLATEX);
        this.setPageSteps();
        callback();
      });
  }

  private setPageSteps(): void {
    if (this.isThirdPartyPlate) {
      this.tabs = this.tpTabs;
    } else {
      this.tabs = this.pTabs;
    }
  }

  // HANDLERS

  public getCarYear(data: DVLAVehicleEnquiryResult): string {
    return data.monthOfFirstRegistration.substring(0, 4);
  }

  public notifyRegistrationHandler(): void {
    this.notified = !this.notified; // UI update
    this.userService.notifyRegistrationString(
      this.registrationString,
      (notifs: Registration[]) => {
        console.log(notifs);
        var _filteredNotifies = notifs.filter(
          (f) =>
            f.registration.replace(' ', '') ==
            this.registrationString.replace(' ', '')
        );
        this.notified = _filteredNotifies && _filteredNotifies.length > 0;
      }
    );
  }

  public toggleSoldDataVisibilityHandler(): void {
    if (this.registrationHistory.loading) {
      this.historyOpen = false;
      return;
    } else {
      this.historyOpen = !this.historyOpen;
    }
  }

  public showMoreDealsClickHandler(): void {
    this.showCompareSellers = true;
  }

  // HELPERS

  public getPlatexPrice(): number {
    if (this.registrationMetaData.loading) return null;
    if (this.registrationMetaData.data == null) return null;
    return this.registrationMetaData.data.platexPrice;
  }

  public getPriceRange(): number[] {
    if (this.registrationPaymentDetails.loading) return null;
    if (this.registrationPaymentDetails.data == null) return null;
    var range: number[] = [0, 0];
    var sellers = this.registrationPaymentDetails.data.sellersResp;
    if (sellers == null || sellers.sellers == null)
      return [this.registrationPaymentDetails.data.price];
    var valid_sellers = sellers.sellers.filter((s) => s.totalPrice > 0);
    if (valid_sellers == null) return range;
    var _sellers = valid_sellers.sort((a, b) => a.totalPrice - b.totalPrice);
    if (_sellers.length == 0) return null;
    if (_sellers.length == 1) return [_sellers[0].totalPrice];
    // range available
    var cheapest = _sellers[0].totalPrice;
    var mostExpensive = _sellers[_sellers.length - 1].totalPrice;
    range = [cheapest, mostExpensive];
    return range;
  }

  public hasSoldData(
    len: number = 1,
    require_different_prices: boolean = false
  ): boolean {
    var show = false;
    show =
      this.registrationHistoryPoints &&
      this.registrationHistoryPoints.length >= len &&
      !this.dvlaPlate;
    if (!show) return show;

    if (require_different_prices) {
      var samePoints = true;
      var lastDataPoint: DataPoint = null;
      this.registrationHistoryPoints.forEach(
        (element: DataPoint, i: number) => {
          if (lastDataPoint == null) lastDataPoint = element;
          if (lastDataPoint.y != element.y) samePoints = false;
        }
      );

      if (samePoints) return false;
    }

    return show;
  }
}
