<div class="page pt-2 pt-lg-4" *ngIf="newPage">
  <div class="content-container extended">
    <div class="ps-5 side-container mb-3">
      <div class="pt-0 position-relative">
        <div class="bg-light text-dark p-3 mb-4" *ngIf="registration">
          <pla-side-bar-filters [filters]="compareFilters"></pla-side-bar-filters>
        </div>

        <pla-side-nav></pla-side-nav>
      </div>

      <div class="bg-light">
        <pla-featured-plates [featureType]="rsFeatured" [plateSize]="'smaller'" [priceFontSize]="16" [maxCount]="10"
          [sortBy]="'price'" [plateClass]="'flat'" [showIndex]="false"></pla-featured-plates>
      </div>
    </div>
    <div class="center-content-container position-relative">
      <div class="pla_card mobile spacing no-top no-card no-left no-right position-relative">
        <div>
          <!-- BREADCRUMBS -->
          <div class="w-100">
            <div
              class="border-bottom d-flex align-items-center justify-content-start gap-2 w-100 px-3 px-lg-0 pb-2 pt-0">
              <a style="color: #327b8d" class="d-block text-decoration-none" [routerLink]="['/']">Home</a>
              <span>/</span>
              <a [style.color]="registration ? '#327b8d' : '#939393'" style="white-space: pre"
                class="text-decoration-none d-block" [routerLink]="['/compare']">Compare</a>
              <span *ngIf="registration">/</span>
              <span *ngIf="registration" style="color: #939393; white-space: pre"
                class="text-decoration-none d-block">{{
                registration }}</span>
            </div>
          </div>
        </div>

        <pla-banner-image [carBanner]="'DIABLO_GT'" [rawImage]="true" [backgroundImage]="true"
          [backgroundClass]="'desktop-mobile-background'" *ngIf="!registration">
          <div class="new-banner-height banner-tint">

            <div class="d-flex justify-content-center align-items-center d-md-none flex-column pt-2 pb-4">
              <div class="p-2">
                <p class="px-3 lh-sm fs-mb-21 text-white text-center mx-auto">COMPARE PRICES ON YOUR
                  NEXT PRIVATE PLATE AND YOU COULD SAVE OVER £172!</p>
              </div>

              <div style="background-color: rgba(13,13,13,0.5); max-width: 75%; min-width: 265px;"
                class="w-100 py-2 px-2 rounded-1">
                <p class="text-white text-center">Enter the exact registration to find the best deals on the market.</p>
                <div>
                  <div
                    class="mx-auto d-flex align-items-stretch justify-content-between border border-2 rounded-2 overflow-hidden"
                    style="border-color: #1B9B8A !important; background-color: #1B9B8A; width: fit-content;">
                    <div
                      class="plate white plate-style-important d-flex align-items-stretch justify-content-center shadow-none"
                      style="border-radius: 4px 0 0 4px;">
                      <input [(ngModel)]="plateToCompare" class="fs-md-18 fsm-mb-16 p-0"
                        style="background: none; max-width: 180px;" placeholder="ENTER REG AND SAVE" />
                    </div>
                    <button style="background-color: #1B9B8A; width: 45px;" (click)="StartCompare()"
                      class="fs-md-12 fs-mb-12 btn text-white d-flex align-items-center justify-content-center px-3 rounded-0"><mat-icon
                        class="">search</mat-icon></button>
                  </div>
                </div>
                <p class="text-white text-center mt-2 lh-base px-2">Take care when entering the letters O and I and
                  numbers 0 and 1.</p>
              </div>
            </div>

            <div class="d-none d-md-flex align-items-start justify-content-between flex-column">
              <div class="p-4 p-md-4">
                <p class="lh-sm fw-bold fs-md-28 fs-mb-24 text-white" style="max-width: 270px;">COMPARE<br />PRIVATE
                  PLATE<br />PRICES AND YOU<br />COULD SAVE<br />OVER<br />£172*</p>
              </div>

              <div class="ps-4">
                <div class="p-3 ps-0">
                  <div
                    class="d-flex align-items-stretch justify-content-between border border-2 rounded-2 overflow-hidden"
                    style="border-color: #1B9B8A !important; background-color: #1B9B8A;">
                    <div
                      class="plate white plate-style-important d-flex align-items-stretch justify-content-center shadow-none"
                      style="border-radius: 4px 0 0 4px;">
                      <input [(ngModel)]="plateToCompare" class="fs-md-18 fsm-mb-16 p-0" (keydown)="EnterSubmit($event)"
                        style="background: none; max-width: 180px;" placeholder="ENTER REG AND SAVE" />
                    </div>
                    <button style="background-color: #1B9B8A; width: 45px;" (click)="StartCompare()"
                      class="fs-md-12 fs-mb-12 btn text-white d-flex align-items-center justify-content-center px-3 rounded-0"><mat-icon
                        class="">search</mat-icon></button>
                  </div>
                </div>
              </div>
            </div>

            <!-- HEADER TEXT -->
            <div *ngIf="false" class="text-white text-center w-100 align-self-center justify-content-center">
              <p class="h1 mb-1 fw-bold">PlateX Compare</p>
              <div>
                <p class="fw-light fs-6 mb-0" style="color: #facf15">
                  Find the best number plate for the best price
                </p>
              </div>
            </div>
          </div>
        </pla-banner-image>

      </div>

      <div class="pla_card mobile spacing no-top no-card no-left no-right position-relative px-0 px-md-1 py-0 me-0">
        <div class="px-0 px-md-2" *ngIf="!registration">

          <div class="pb-3 pt-3 px-3 px-md-0" *ngIf="hasCompareHistory()">
            <div class="w-100">
              <pla-user-history [type]="'compare'" [title]="'Your Deals'"></pla-user-history>
            </div>
          </div>

          <div *ngIf="false" class="pb-3 pt-3 px-3 px-md-0">
            <p class="fs-mb-14 fs-md-16 mb-0">99.9% of private plates are offered for sale with more than one
              dealer.
              Find the best deal on over 72 million private plates listed with 35 registration dealers with Plate
              Compare</p>
          </div>

          <!-- CARDS -->
          <div class="pb-3 pt-0 pt-md-3">
            <div class="container-fluid mx-auto w-100">

              <div class="row px-3 py-3" style="background-color: #032933;">
                <p class="text-white text-center text-md-start mb-0 fs-md-26 fs-mb-22 px-4 px-md-0">How our free price
                  comparison works</p>
              </div>

              <ng-container *ngFor="let card of cards; let index = index">
                <div class="row px-3 py-5" style="min-height: 290px;"
                  [style.background]="index % 2 == 0 ? '#ffffff' : '#032933'">
                  <div
                    class="px-5 px-md-0 col-12 col-sm-6 col-xl-3 d-flex align-items-center justify-content-center order-1 {{ index % 2 == 0 ? 'order-xl-1' : 'order-xl-3' }}">
                    <img class="d-block mx-auto w-100" style="max-width: 200px;" [src]="card.number" />
                  </div>

                  <div
                    class="py-3 py-md-3 px-4 px-md-5 col-12 col-sm-6 col-xl-4 d-flex align-items-center justify-content-center order-2 order-xl-2">
                    <img class="d-block mx-auto w-100" style="max-width: 250px; max-height: 200px; object-fit: contain;"
                      src="{{ card.image }}" />
                  </div>

                  <div
                    class="mt-3 mt-xl-0 col-12 col-xl-5 d-flex align-items-center justify-content-center order-3 {{ index % 2 == 0 ? 'order-xl-3' : 'order-xl-1' }}">
                    <div>
                      <p
                        class="{{ index % 2 == 0 ? '' : 'text-white' }} fw-bold fs-md-18 fs-mb-16 text-center text-xl-start">
                        {{ card.title }}</p>
                      <p
                        class="{{ index % 2 == 0 ? '' : 'text-white' }} fs-md-16 fs-mb-14 mb-0 text-center text-xl-start">
                        {{ card.body }}</p>
                    </div>
                  </div>
                </div>
              </ng-container>
            </div>
          </div>

          <div style="height: 50px;"></div>

          <div class="pb-5 pt-5">
            <p class="text-center text-md-start fs-md-26 fs-mb-22">Frequently Asked Questions</p>
          </div>
          <div style="height: 50px;"></div>
          <div class="w-100 px-3 px-md-0 pb-5">
            <pla-faq [faq]="faq"></pla-faq>
          </div>

          <div style="height: 50px;"></div>
        </div>

        <!-- loaded content -->
        <div *ngIf="registration"
          class="pla_card mobile spacing no-top no-card no-left no-right position-relative py-0">
          <div class="px-2 px-md-1 mx-auto" style="max-width: 1000px">
            <div class="container-fluid">
              <div class="row">
                <div class="col-12 col-md-6 order-2 order-md-1">
                  <div class="w-100 h-100 p-3 text-center d-flex flex-column justify-content-center align-items-center">
                    <div>
                      <pla-plate [rawPlate]="registration" [white]="false" [mobileSmall]="false" [openModal]="false"
                        [showPrice]="false" [useCustom]="true" [customSize]="9" [customFontSize]="40"></pla-plate>
                    </div>

                    <div *ngIf="detail">
                      <p class="text-center mt-3 mb-0" *ngIf="detail.min !== 0">
                        Prices From
                      </p>
                      <p class="mb-0" *ngIf="detail.min !== 0 && detail.min != detail.max">
                        <strong class="fs-5">{{
                          detail.min | currency : "GBP"
                          }}</strong>
                        to
                        <strong class="fs-5">{{
                          detail.max | currency : "GBP"
                          }}</strong>
                      </p>
                      <p class="mb-0" *ngIf="detail.min !== 0 && detail.min == detail.max">
                        <strong class="fs-5">{{
                          detail.min | currency : "GBP"
                          }}</strong>
                      </p>
                      <small class="text-muted">Details last updated on
                        {{ detail.lastUpdate | date : "dd/MM/yy" }}</small>
                    </div>
                  </div>
                </div>

                <div class="col-12 col-md-6 order-1 order-md-2">
                  <div class="overflow-hidden bg-light" style="border-radius: 25px">
                    <img class="w-100 d-block m-auto" style="max-height: 6000px"
                      *ngIf="selectedImage && selectedImage.image" [src]="selectedImage.image" />
                  </div>
                </div>
              </div>

              <!-- PLATE INFO -->
            </div>
          </div>

          <pla-sticky-tabs [config]="stickyLinkConfig"></pla-sticky-tabs>
          <div style="background-color: #F1F4F2">
            <div class="pb-3 w-100 position-relative">
              <div class="position-absolute" style="top: -92px" id="compare-deals"></div>
              <pla-tp-sellers *ngIf="registrationLoaded" (PriceRangeChange)="PriceRangeChange($event)"
                (resultsChange)="CompareResultsChange($event)" (compareFilters)="SetCompareFilters($event)"
                [registration]="registration"></pla-tp-sellers>
            </div>

            <div class="pt-3 pb-3 w-100 px-3 px-md-1 position-relative border-top">
              <div class="position-absolute" style="top: -92px" id="history"></div>
              <div class="px-0 px-md-4 mx-auto" style="max-width: 700px">
                <div>
                  <p class="mb-2 fs-5">Price History</p>
                  <p>
                    The price graph shows price changes over time including both
                    price changes and verified sales.
                  </p>
                </div>
                <div>
                  <table *ngIf="detail.plateDetail" class="text-start w-100 my-3 ms-0 me-auto" style="max-width: 350px">
                    <tr>
                      <td class="fs-6 fw-bolder pe-2">Registration Style</td>
                      <td class="fs-6">{{ detail.plateDetail.style }}</td>
                    </tr>
                    <tr>
                      <td class="fs-6 fw-bolder pe-2">First Issued</td>
                      <td class="fs-6">
                        {{ detail.plateDetail.issueMonth }}
                        {{ detail.plateDetail.issueYear }}
                      </td>
                    </tr>
                    <tr>
                      <td class="fs-6 fw-bolder pe-2">Region</td>
                      <td class="fs-6">{{ detail.plateDetail.issueRegion }}</td>
                    </tr>
                  </table>
                </div>
                <div class="bg-white p-3 shadow-sm rounded-3">
                  <pla-price-history *ngIf="registrationLoaded" [registration]="registration"></pla-price-history>
                </div>
              </div>
            </div>

            <div class="pt-3 pb-3 w-100 px-3 px-md-1 position-relative border-top">
              <div class="position-absolute" style="top: -92px" id="market-insight"></div>
              <div>
                <p class="mb-2 fs-5">Market Insight</p>
                <p>
                  These are the key market insights of the current style "69"
                  series and current he entire style of cherished registration.
                </p>
              </div>
              <pla-market-insight *ngIf="registrationLoaded && detail.loaded" [registration]="registration"
                [cheapestRegPrice]="detail.min"></pla-market-insight>
            </div>

            <div class="pt-3 pb-3 w-100 px-3 px-md-1 position-relative bg-white border-top">
              <div class="position-absolute" style="top: -92px" id="faq"></div>
              <div>
                <p class="mb-2 fs-5">FAQ's</p>
                <pla-faq [faq]="faq"></pla-faq>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="content-container" *ngIf="false">
    <pla-banner-image class="w-100" [rawImage]="true" [backgroundImage]="true" [carBanner]="'AVENTADOR_S_FOOTER'"
      [backgroundClass]="'w-100 desktop-mobile-bottom'">
      <div class="d-flex align-items-stretch justify-content-end new-banner-height banner-tint">
        <div
          class="w-75 w-md-100 p-4 ps-5 ps-md-4 d-flex align-items-center justify-content-center ltr_gradient md-no-gradient">
          <div class="d-flex align-items-center justify-content-center flex-column ms-auto me-0"
            style="max-width: 450px">
            <p class="fs-5 text-white">
              With the entire market of registrations on one single portal,
              industry leading optimised search facilities and even price
              comparison it's no wonder buyers are choosing PlateX to find their
              perfect plate.
            </p>

            <div class="align-self-end mt-4">
              <button class="ms-auto me-0 turquoise-button" mat-flat-button>
                Begin Free Trial
              </button>
            </div>
          </div>
        </div>
      </div>
    </pla-banner-image>
  </div>
</div>

<div class="page" *ngIf="!newPage">
  <div class="main-content-container content-container no-ds">
    <div class="center-content-container">
      <div class="pla_card mobile spacing no-top no-ds no-card no-left no-right">
        <pla-banner-image [showShadow]="false" [carBanner]="'PORSCHE_TAYCAN'">
          <div class="page-title">
            <h1>PRICE COMPARISON</h1>
            <span class="sub">Search and Save</span>
          </div>
        </pla-banner-image>

        <div class="search-input-container">
          <div class="search-area inner">
            <div class="box-container">
              <pla-option-search [endpoint]="'compare'" [optionsEnabled]="false" [showHeaderText]="false"
                [searchCriteria]="registration" [plateStyle]="true" [gotoProductPage]="false"></pla-option-search>
              <span class="header">Enter the registration for price comparison</span>
            </div>
          </div>
        </div>

        <!-- RESULTS -->
        <div class="pla_card no-card mobile spacing padding no-left no-right">
          <ng-container *ngIf="registrationLoaded; else noRegistration">
            <ng-container *ngIf="validRegistration">
              <pla-compare-results-table *ngIf="registration; else noRegistration"
                [registration]="registration"></pla-compare-results-table>
            </ng-container>
            <ng-container *ngIf="!validRegistration">
              <div class="text-section">
                <div class="text-title heading">
                  <h2>Invalid Registration</h2>
                </div>
                <div class="text-paragraph">
                  <p>Please enter a valid registration to continue.</p>
                </div>
              </div>
            </ng-container>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #noRegistration>
  <!-- Page steps -->
  <div class="pla_card no-card mobile spacing padding">
    <div class="text-section">
      <div class="text-title heading">
        <h2>Compare</h2>
      </div>
      <div class="text-paragraph">
        <p>
          Simply enter the exact registration (taking care to only use the
          number 0 and letter O correctly) to find the best deal available for
          your perfect registration.
        </p>
      </div>

      <div class="text-paragraph">
        <div class="faq-item">
          <mat-icon>help_outline</mat-icon>
          <h4>How do you show so many registrations prices?</h4>
        </div>
        <p class="indent">
          Most registration prices are updated automatically while others by our
          team at least once a day, some several times an hour such as those in
          an active DVLA auction. However, dealers may update their prices more
          often and consequently PlateX does not guarantee that prices are
          correct. Always double-check the price on the dealer's website before
          completing a purchase to ensure that the price you saw at PlateX is
          correct.
        </p>

        <div class="faq-item">
          <mat-icon>help_outline</mat-icon>
          <h4>Are the prices shown fully inclusive of VAT and shipping?</h4>
        </div>
        <p class="indent">
          While we endeavour to show all prices inclusive of shipping for
          technical or resource reasons, some dealer's price may miss shipping
          cost information or only be included at checkout. In more rare cases
          some dealer prices may exclude VAT in our listings. If we are informed
          that a dealer has not displayed prices with VAT, we will add a note to
          “check with the dealer” to prompt users to refer to the dealer's site
          yourself to check whether VAT and shipping costs are inclusive. PlateX
          currently compares the prices of 68 million unique registrations from
          over 35 dealers, the DVLA and auctions. In some instances there may
          only be a single price for a registration if the dealer chooses to be
          the sole supplier of a registration, a private sales listing or a DVLA
          auction.
        </p>
      </div>
    </div>
  </div>
</ng-template>