<div class="page pt-2 pt-md-4">
    <div class="content-container extended">
        <div class="ps-5 side-container mb-3">
            <div class="pt-0 position-relative">
                <pla-side-nav></pla-side-nav>
            </div>

            <div class="bg-light">
                <!-- <pla-featured-plates [featureType]="rsFeatured" [plateSize]="'smaller'" [priceFontSize]="16"
                    [maxCount]="10" [sortBy]="'price'" [plateClass]="'flat'" [showIndex]="false"></pla-featured-plates> -->
            </div>
        </div>
        <div class="center-content-container position-relative">
            <div class="pla_card mobile spacing no-top no-card no-left no-right position-relative">
                <div>
                    <!-- BREADCRUMBS -->
                    <div class="w-100">
                        <div
                            class="border-bottom d-flex align-items-center justify-content-start gap-2 w-100 px-3 px-lg-0 pb-2 pt-0">
                            <a style="color: #327b8d" class="d-block text-decoration-none" [routerLink]="['/']">Home</a>
                            <span>/</span>
                            <a style="white-space: pre; color: #327b8d" class="text-decoration-none d-block"
                                [routerLink]="['/articles']">Resources</a>
                            <span>/</span>
                            <span style="color: #939393; white-space: pre" class="text-decoration-none d-block">Number
                                Plate Types</span>
                        </div>
                    </div>
                </div>
            </div>

            <div
                class="pla_card mobile spacing no-top no-card no-left no-right position-relative px-0 px-md-1 py-0 me-0">
                <div class="px-0 px-md-2">

                    <div class="mx-3 mx-md-0">

                        <div class="mt-4 mb-4 border-bottom border-2" style="border-color: #3791A8 !important;">
                            <p class="fs-mb-16 fs-md-18 fw-bold text-start mx-0">How to Retain a Private Plate
                            </p>
                        </div>

                        <div>
                            <p class="fs-md-14 fs-mb-14 text-dark d-block mb-4">
                                Retaining a cherished registration allows you to keep a private number plate without
                                assigning it to a vehicle immediately. Here are the steps to retain a cherished
                                registration in the UK:
                            </p>
                            <div>
                                <ul class="platex-list mb-2">
                                    <li>Eligibility Criteria</li>
                                </ul>
                                <p class="fs-md-14 fs-mb-14 text-dark d-block mb-0">
                                    Before you start the retention process, ensure your vehicle meets the following
                                    criteria:
                                </p>
                                <p class="fs-md-14 fs-mb-14 text-dark d-block mb-0">
                                    The vehicle must be registered with the DVLA in the UK.
                                </p>
                                <p class="fs-md-14 fs-mb-14 text-dark d-block mb-0">
                                    It must be able to move under its own power.
                                </p>
                                <p class="fs-md-14 fs-mb-14 text-dark d-block mb-0">
                                    It must require an MOT or heavy goods vehicle (HGV) test certificate.
                                </p>
                                <p class="fs-md-14 fs-mb-14 text-dark d-block mb-0">
                                    The vehicle must be available for inspection if requested by the DVLA.
                                </p>
                                <p class="fs-md-14 fs-mb-14 text-dark d-block mb-0">
                                    It must have been taxed or had a SORN continuously for the past 5 years.
                                </p>
                                <p class="fs-md-14 fs-mb-14 text-dark d-block mb-0">
                                    It must be currently taxed or have a SORN in place. If SORN has been in place for
                                    over 5 years, the vehicle must be taxed and have an MOT certificate.
                                </p>
                                <p class="fs-md-14 fs-mb-14 text-dark d-block mb-4">
                                    For historic (classic) vehicles, a current MOT certificate is required, even if
                                    usually exempt.
                                </p>
                            </div>

                            <div>
                                <ul class="platex-list mb-2">
                                    <li>Application Process</li>
                                </ul>
                                <p class="fs-md-14 fs-mb-14 text-dark d-block mb-2">
                                    Online Application:
                                </p>
                                <p class="fs-md-14 fs-mb-14 text-dark d-block mb-2">
                                    Visit the DVLA website: Go to the DVLA’s personalised registration service.
                                    Complete the online form: Provide the necessary details and submit the application.
                                    Payment: Pay the £80 fee for the retention service.
                                    Immediate Removal: The cherished registration will be removed immediately if no
                                    inspection is required.
                                    Receive Documentation: You will receive a V778 retention document via post and can
                                    use the reference number provided to assign the cherished registration to another
                                    vehicle later.
                                </p>
                            </div>
                        </div>
                    </div>

                    <div style="height: 50px;"></div>

                    <div>
                        <div class="container-fluid row mx-auto">
                            <div class="col-12 col-md-5 px-0">
                                <img src="https://cdn.platex.co.uk/pages/articles/v317_template.png"
                                    class="d-block w-100" />
                                <a href="https://assets.publishing.service.gov.uk/media/662b76131179821456b81e68/v317-application-to-transfer-or-retain-a-vehicle-registration-number.pdf"
                                    class="fs-md-14 fs-mb-14 text-dark d-block mb-2 text-center text-dark">V317 -
                                    Application to
                                    Transfer a Registration</a>
                            </div>

                            <div class="col-12 col-md-2 px-0"></div>

                            <div class="col-12 col-md-5 px-0">
                                <img src="https://cdn.platex.co.uk/pages/articles/v778_template.png"
                                    class="d-block w-100" />
                                <p class="fs-md-14 fs-mb-14 text-dark d-block mb-2 text-center">V778 - Retention
                                    Certificate</p>
                            </div>
                        </div>
                    </div>

                    <div style="height: 50px;"></div>

                    <div>
                        <p class="fs-md-14 fs-mb-14 text-dark d-block mb-2">
                            Postal Application:
                        </p>
                        <p class="fs-md-14 fs-mb-14 text-dark d-block mb-1">
                            Download and Complete Form V317: The form is titled “Application to transfer or retain a
                            vehicle registration number.”
                            Prepare Additional Documents: Include the vehicle’s log book (V5C) or green ‘new keeper’
                            slip with a completed V62 form.
                            Payment: Include a cheque or postal order for the £80 fee.
                            Mail the Application: Send the completed form and documents to the DVLA.
                            Receive Documentation: You will receive a V778 retention document and a new log book (V5C)
                            showing the vehicle’s replacement registration number.
                            After Retention
                        </p>
                        <p class="fs-md-14 fs-mb-14 text-dark d-block mb-2">
                            V778 Retention Document: This document proves that you still have the right to assign the
                            cherished registration to a vehicle in the future. It is valid for 10 years and must be
                            renewed before it expires.
                            New Log Book (V5C): Your vehicle will receive its original registration number or a new one,
                            which will be shown on the new log book sent to you.
                            Insurance Update: Inform your insurance company about the change in registration number.
                            Renewing the Retention

                        </p>
                        <p class="fs-md-14 fs-mb-14 text-dark d-block mb-2">
                            To keep the cherished registration valid, you must renew the V778 retention document every
                            10 years. You can renew:
                        </p>
                        <p class="fs-md-14 fs-mb-14 text-dark d-block mb-4">
                            Online: Using the DVLA personalised registration account.
                            By Post: By filling in the form on the V778 document and sending it to the DVLA.
                            If the document is lost or stolen, you can apply for a replacement provided it has not
                            expired and you are the registered owner.
                        </p>
                    </div>
                    <div style="height: 50px;"></div>
                </div>
            </div>
        </div>
    </div>

    <div class="bg-light">
        <div class="content-container extended">
            <div class="blog-content-container position-relative py-3 mb-3 ps-5 pe-5">
                <p class="h3 fw-bolder text-start mb-2">In Other News</p>
                <pla-featured-blogs></pla-featured-blogs>
            </div>
        </div>
    </div>
</div>