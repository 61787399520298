<div class="w-100">
    <div class="w-100">
        <div class="d-block d-md-none" style="height: 40px;"></div>

        <div class="d-block d-md-none content-container extended">
            <div class="px-0 px-md-2 w-100 mx-auto" style="max-width: 1000px;">
                <div class="px-4 px-md-3">
                    <p class="fs-md-26 fs-mb-24 lh-sm fw-bolder text-start h-mb">Find Your Perfect Plate - We Search,
                        You Compare</p>

                    <div class="h-mb">
                        <div class="">
                            <div class="mb-3 mx-auto d-flex align-items-stretch justify-content-between border border-2 rounded-2 overflow-hidden"
                                style="border-color: #327B8D !important; background-color: #327B8D;">
                                <div class="plate white plate-style-important d-flex py-2 align-items-stretch justify-content-center shadow-none w-100"
                                    style="border-radius: 4px 0 0 4px;">
                                    <input class="fs-md-18 fsm-mb-16 p-0" style="background: none; max-width: 350px;"
                                        placeholder="What can we find for you?" />
                                </div>
                                <button style="background-color: #327B8D; max-width: 45px;"
                                    class="fs-md-12 fs-mb-12 btn text-white d-flex align-items-center justify-content-center px-2 rounded-0 gap-1">
                                    <mat-icon class="">search</mat-icon>
                                </button>
                            </div>
                        </div>

                        <div class="w-100 d-flex align-items-center justify-content-center gap-2 flex-wrap mx-auto"
                            style="max-width: 400px;">
                            <a style="border-color: #484848;" [routerLink]="'/dateless-search'"
                                class="text-decoration-none border border-secondary rounded-3 text-dark px-2 py-1 fs-md-18 fs-mb-16">Dateless</a>
                            <a style="border-color: #484848;" [routerLink]="'/byo-search'"
                                class="text-decoration-none border border-secondary rounded-3 text-dark px-2 py-1 fs-md-18 fs-mb-16">Build-Your-Own</a>
                            <a style="border-color: #484848;" [routerLink]="'/initials-search'"
                                class="text-decoration-none border border-secondary rounded-3 text-dark px-2 py-1 fs-md-18 fs-mb-16">Initials</a>
                        </div>
                    </div>

                    <p class="lh-sm fw-light fs-md-18 fs-mb-16 text-start mb-2">With PlateX, you can search the entire
                        market for registrations using the industry's most advanced search tool.</p>
                    <p class="lh-sm fw-light fs-md-18 fs-mb-16 text-start mb-4">Simply enter anything, and we'll do the
                        searching. When you find a plate you like, use our free price comparison to get the best deal.
                    </p>
                </div>
            </div>
        </div>

        <div class="d-none d-md-block">
            <p style="max-width: 1000px;"
                class="mx-auto d-block w-100 lh-sm fw-light fs-md-18 fs-mb-16 text-center mb-4">
                With PlateX you can search through the entire
                market of registrations for sale and see better results using the industry's most advanced search
                facility. Just enter anything and let us do the searching for you. When you found a private plate you
                like use our free price comparison service to find the best deal. </p>
        </div>

        <div class="s-spacing"></div>

        <div>
            <div class="content-container extended">
                <div class="w-100 px-0">
                    <div class="px-4">
                        <p class="fs-md-26 fs-mb-24 lh-sm fw-bolder text-start h-mb">How It Works?</p>
                    </div>
                </div>
            </div>

            <div class="content-container extended" style="background: #F7F7F7;">
                <div class="w-100 px-0">
                    <div class="px-4 rountainer-fluid">
                        <div class="px-2 py-2 rounded row">
                            <ng-container *ngFor="let card of cards">
                                <!-- DESKTOP -->
                                <div class="d-none d-md-block flex-fill mb-3 col-md-4 col-sm-7 col-12 p-2">
                                    <div
                                        class="px-3 py-3 h-100 d-flex align-items-center justify-content-start justify-content-md-between flex-column bg-white rounded-3 shadow-sm mx-auto" style="max-width: 350px">
                                        <div class="w-100 d-flex align-items-start justify-content-between mb-3">
                                            <p style="color: #0A6077;"
                                                class="lh-1 w-20 h-100 flex-fill my-0 fs-mb-30 fs-md-35 text-start fw-normal">
                                                {{
                                                card.number }}</p>
                                            <div class="flex-fill w-60">
                                                <p
                                                    class="lh-1 flex-fill w-100 my-0 fs-mb-20 fs-md-18 text-center fw-normal">
                                                    {{ card.title }}</p>
                                            </div>
                                            <p class="w-20 flex-fill px-1 py-1 my-0"></p>
                                        </div>

                                        <div>
                                            <img [src]="card.image" style="max-width: 140px;"
                                                class="mx-auto w-100 d-block mb-2 card-image" />
                                            <p style="min-height: 80px;"
                                                class="fs-md-16 fs-mb-16 lh-sm fw-light my-0 text-dark text-center">{{
                                                card.body
                                                }}</p>
                                        </div>
                                    </div>
                                </div>

                                <!-- MOBILE -->
                                <div class="d-block d-md-none flex-fill mb-3 col-md-4 col-sm-7 col-12">
                                    <div
                                        class="px-3 py-3 h-100 d-flex align-items-center justify-content-start justify-content-md-between flex-column">
                                        <div class="w-100 d-flex align-items-start justify-content-between mb-3">
                                            <p style="color: #0A6077;"
                                                class="lh-1 w-20 h-100 flex-fill my-0 fs-mb-30 fs-md-35 text-start fw-normal">
                                                {{
                                                card.number }}</p>
                                            <div class="flex-fill w-60">
                                                <img [src]="card.image" style="max-width: 140px;"
                                                    class="mx-auto w-100 d-block card-image" />
                                            </div>
                                            <p class="w-20 flex-fill px-1 py-1 my-0"></p>
                                        </div>

                                        <div>
                                            <p
                                                class="lh-1 flex-fill w-100 my-0 mb-2 fs-mb-20 fs-md-22 text-center fw-normal">
                                                {{ card.title }}</p>
                                            <p style="min-height: 80px;"
                                                class="fs-md-16 fs-mb-16 lh-sm fw-light my-0 text-dark text-center">{{
                                                card.body
                                                }}</p>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="s-spacing"></div>

        <div class="content-container extended">
            <div class="container-fluid w-100 px-4">
                <div class="row px-0">
                    <p class="fs-md-26 fs-mb-24 lh-sm fw-bolder text-start h-mb">Curated Collections</p>
                </div>
                <div class="row px-0">
                    <div class="col-12 col-md-4 mb-3">
                        <a href="/new-release/74" class="text-decoration-none d-block">
                            <img src="https://cdn.platex.co.uk/pages/landing/74_plate_tab.png" class="d-block w-100" />
                        </a>
                    </div>
                    <div class="col-12 col-md-4 mb-3">
                        <a href="/new-release/24" class="text-decoration-none d-block">
                            <img src="https://cdn.platex.co.uk/pages/landing/24_plate_tab.png" class="d-block w-100" />
                        </a>
                    </div>
                    <div class="col-12 col-md-4 mb-3">
                        <a href="/number-1" class="text-decoration-none d-block">
                            <img src="https://cdn.platex.co.uk/pages/landing/number_1_tab.png" class="d-block w-100" />
                        </a>
                    </div>
                    <div class="col-12 col-md-4 mb-3">
                        <a href="/under-100" class="text-decoration-none d-block">
                            <img src="https://cdn.platex.co.uk/pages/landing/under_100_tab.png" class="d-block w-100" />
                        </a>
                    </div>
                    <div class="col-12 col-md-4 mb-3">
                        <a href="/names" class="text-decoration-none d-block">
                            <img src="https://cdn.platex.co.uk/pages/landing/exclusive_names_tab.png"
                                class="d-block w-100" />
                        </a>
                    </div>
                </div>
            </div>
        </div>

        <div class="s-spacing"></div>

        <!-- FEATURED LISTING -->

        <div class="content-container extended">
            <div class="container-fluid w-100 px-4">
                <div class="row px-0">
                    <p class="px-4 fs-md-26 fs-mb-24 lh-sm fw-bolder text-start h-mb">Featured (1/1 Names)</p>
                </div>
                <div class="px-0 row">
                    <div style="max-width: 1180px;" class="mx-auto">
                        <pla-featured-plates [featureType]="meFeatured" [showTitle]="false" [priceFontSize]="16"
                            [maxCount]="10" [sortBy]="'price'" [plateClass]="'flat'" [mobileTiny]="false"
                            [plateSize]="'nr-s'" [showIndex]="false"></pla-featured-plates>
                    </div>
                </div>
            </div>
        </div>

        <div class="s-spacing"></div>


        <div class="position-relative d-block d-md-none">
            <img src="https://cdn.platex.co.uk/pages/landing/LOCATE_GT3RS_BANNER_MB.png" class="d-block w-100" />
            <div class="position-absolute top-0 end-0 bottom-0 start-0">
                <div class="h-100 px-4 py-4" style="background-color: rgba(0,0,0,0.05);">
                    <div class="h-100 d-flex flex-column align-items-start justify-content-between w-100">
                        <div>
                            <p class="fs-md-26 fs-mb-24 lh-sm fw-bolder text-start mb-2 text-white">Plate Locate</p>
                            <p class="lh-sm fw-light fs-md-18 fs-mb-16 text-start text-white">Spotted your dream
                                plate
                                or want to
                                reunite Dad with his first car registration? We have created the tools to help.
                            </p>
                        </div>

                        <!-- <div style="height: 350px;"></div> -->

                        <div class="pt-5 w-100">
                            <button
                                class="white-border-button d-block w-100 rounded-3 fs-md-20 fs-mb-18 py-3 px-3 fw-normal">
                                Notify
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="content-container extended">
            <div class="d-none d-md-block">
                <div class="container-fluid w-100 py-0 px-0">
                    <div class="px-0 position-relative w-100">
                        <div class="position-absolute start-0 top-0 bottom-0 d-flex flex-column justify-content-between align-items-start p-4"
                            style="background-color: rgba(15, 15, 15, 0.56); width: fit-content; max-width: 400px;">
                            <div>
                                <p class="fs-md-26 fs-mb-20 text-start w-100 d-block text-white mb-4">Plate Locate
                                </p>
                                <p class="fs-md-16 fs-mb-16 fw-light mb-4 text-white">Whether you just saw your must
                                    have
                                    number plate drive by or Dad wants to be reunited
                                    with
                                    his first ever car registration we are here to help!</p>
                            </div>
                            <div
                                class="mt-4 ms-auto me-0 d-flex align-items-stretch justify-content-between rounded-2 overflow-hidden w-100">
                                <button
                                    class="white-border-button d-block w-100 rounded-3 fs-md-20 fs-mb-18 py-3 px-3 fw-normal">
                                    Notify
                                </button>
                            </div>
                        </div>
                        <img src="https://cdn.platex.co.uk/pages/landing/LOCATE_GT3RS_BANNER.png"
                            class="d-block w-100" />
                    </div>
                </div>
            </div>
        </div>

        <div class="s-spacing"></div>

        <div style="background-color: #F1F4F2;">
            <div class="content-container extended">
                <div class="w-100 px-0 py-4">
                    <div class="px-4">
                        <p class="fs-md-26 fs-mb-24 lh-sm fw-bolder text-start my-0">New Listings</p>
                    </div>
                    <div>
                        <pla-featured-listings></pla-featured-listings>
                    </div>
                    <div class="px-4">
                        <p class="fs-md-16 fs-mb-16 fw-light my-0 text-dark text-start"><strong class="fw-bolder">Sell
                                With
                                Style -</strong> Along
                            with appearing in search results your plate will be showcased in photorealistic quality in
                            the
                            New Listing feature reaching thousands of buyers.</p>
                    </div>
                </div>
            </div>
        </div>

        <div class="s-spacing"></div>

        <div class="content-container extended my-4 px-4">
            <div class="container-fluid w-100 py-3 py-md-5 px-3 px-md-5 rounded-lg" style="background-color: #F4EBE7;">
                <div class="row">
                    <div class="col-12 col-md-6">
                        <div class="d-block d-md-none">
                            <p class="fs-md-26 fs-mb-24 lh-sm fw-bolder h-mb text-start">Plate Viewer</p>
                        </div>
                        <div class="d-block mb-md-0 mb-3">
                            <img src="https://cdn.platex.co.uk/pages/landing/plate_viewer_banner.png"
                                class="d-block w-100" />
                        </div>
                    </div>
                    <div class="col-12 col-md-6 d-flex flex-column align-items-start justify-content-center">
                        <div class="d-none d-md-block mb-3">
                            <p class="fs-md-26 fs-mb-20 text-center text-md-start w-100 d-block">Try Our Plate Viewer
                            </p>
                        </div>
                        <div>
                            <p class="fs-md-16 fs-mb-16 fw-light mb-4" style="color: #2F2F2F;">Enter anything into our
                                state of the art plate viewer to see it
                                in photorealistic quality on a range of incredible cars</p>

                            <div class="mb-4">
                                <div>
                                    <div class="ms-0 me-auto d-flex align-items-stretch justify-content-between border border-2 rounded-2 overflow-hidden"
                                        style="border-color: #1B9B8A !important; background-color: #1B9B8A; width: fit-content;">
                                        <div class="plate white plate-style-important d-flex align-items-stretch justify-content-center shadow-none"
                                            style="border-radius: 4px 0 0 4px;">
                                            <input class="fs-md-24 fsm-mb-16 p-0" [(ngModel)]="plateViewerPlate"
                                                style="background: none; max-width: 180px;" placeholder="ENTER REG" />
                                        </div>
                                        <button style="background-color: #1B9B8A;" (click)="OpenPlateViewerWithPlate()"
                                            class="fs-md-12 fs-mb-12 btn text-white d-flex align-items-center justify-content-center px-3 rounded-0 gap-1">
                                            <span class="text-white fs-md-18 fsm-mb-16 p-0">View</span>
                                            <mat-icon class="">chevron_right</mat-icon>
                                        </button>
                                    </div>
                                </div>
                            </div>

                            <div class="d-flex align-items-center justify-content-start gap-2 flex-wrap ms-0 me-auto"
                                style="max-width: 400px;">
                                <span (click)="OpenPlateViewerWithCar('cybertruck')"
                                    class="pointer border border-secondary rounded-3 text-secondary px-2 py-1 fs-md-18 fs-mb-16">CyberTruck</span>
                                <span (click)="OpenPlateViewerWithCar('laferrari')"
                                    class="pointer border border-secondary rounded-3 text-secondary px-2 py-1 fs-md-18 fs-mb-16">LaFerrari</span>
                                <span (click)="OpenPlateViewerWithCar('aventador')"
                                    class="pointer border border-secondary rounded-3 text-secondary px-2 py-1 fs-md-18 fs-mb-16">Aventador</span>
                                <span (click)="OpenPlateViewerWithCar('mini cooper s')"
                                    class="pointer border border-secondary rounded-3 text-secondary px-2 py-1 fs-md-18 fs-mb-16">Mini</span>
                                <span (click)="OpenPlateViewerWithCar('g wagon')"
                                    class="pointer border border-secondary rounded-3 text-secondary px-2 py-1 fs-md-18 fs-mb-16">G
                                    Wagon</span>
                                <span (click)="OpenPlateViewerWithCar('countach')"
                                    class="pointer border border-secondary rounded-3 text-secondary px-2 py-1 fs-md-18 fs-mb-16">Countach</span>
                                <span (click)="OpenPlateViewerWithCar('fiat 500')"
                                    class="pointer border border-secondary rounded-3 text-secondary px-2 py-1 fs-md-18 fs-mb-16">Fiat
                                    500</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="s-spacing"></div>

        <div class="d-block d-md-none" style="background-color: #F1F4F2;">
            <div class="p-4">
                <p class="fs-md-26 fs-mb-24 lh-sm fw-bolder h-mb text-start">Value your plate</p>
                <div class="d-flex align-items-center justify-content-between gap-3">
                    <div class="w-100">
                        <p class="fs-md-16 fs-mb-16 fw-light mb-4 lh-sm" style="color: #2F2F2F;">Harness the power of
                            the UK's
                            largest registration database for an evidence driven FREE and INSTANT valuation
                            estimate!</p>
                    </div>
                    <div class="flex-shrink-0" style="max-width: 135px;">
                        <img src="https://cdn.platex.co.uk/pages/landing/value_plate.png" class="d-block w-100" />
                    </div>
                </div>

                <!-- PLATE -->
                <div class="d-flex align-items-center justify-content-between gap-4 mb-3">
                    <div>
                        <p class="text-nowrap text-dark fs-md-16 fs-mb-16 fw-light my-0 lh-sm">Enter your Reg</p>
                    </div>
                    <div class="input-container plate-background" style="max-width: 160px;">
                        <input class="plate-font" type="text" spellcheck="false" autocomplete="false"
                            placeholder="YOUR REG" />
                    </div>
                </div>

                <button
                    class="d-block bg-transparent w-100 rounded-3 platex-border-button fs-md-20 fs-mb-18 py-3 px-3 mt-3">
                    Value My Registration
                </button>
            </div>
        </div>

        <div class="d-none d-md-block my-4" style="background-color: #1E3238;">
            <div class="content-container extended px-0 px-md-4">
                <div class="container-fluid py-0 px-0">
                    <div class="row mx-0">
                        <div class="order-1 order-md-0 col-12 col-md-6 px-0">
                            <div
                                class="d-flex flex-column align-items-start justify-content-center px-4 px-md-5 py-3 py-md-5">
                                <div class="mb-3">
                                    <p class="fs-md-26 fs-mb-20 text-center text-md-start w-100 d-block text-white">Find
                                        out the value of your plate</p>
                                </div>
                                <div>
                                    <p class="fs-md-16 fs-mb-16 fw-light mb-4 text-white">Harness the power of the UK's
                                        largest registration database for an evidence driven FREE and INSTANT valuation
                                        estimate!
                                    </p>
                                </div>

                                <div>
                                    <div>
                                        <!-- PLATE -->
                                        <div class="d-flex align-items-center justify-content-between gap-4 mb-3">
                                            <div>
                                                <small class="text-nowrap text-white">Enter your
                                                    Registration<br />Number</small>
                                            </div>
                                            <div class="input-container plate-background" style="max-width: 160px;">
                                                <input class="plate-font" type="text" spellcheck="false"
                                                    autocomplete="false" placeholder="YOUR REG" />
                                            </div>
                                        </div>
                                    </div>

                                    <div
                                        class="mt-4 ms-0 me-auto d-flex align-items-stretch justify-content-between rounded-2 overflow-hidden">
                                        <button style="background-color: transparent;"
                                            class="fs-md-12 fs-mb-12 btn d-flex align-items-center justify-content-start gap-1 p-0">
                                            <span style="color: #FFDD0C" class="fs-md-18 fsm-mb-16 p-0">Value Your
                                                Plate</span>
                                            <mat-icon style="color: #FFDD0C;" class="">chevron_right</mat-icon>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="order-0 order-md-1 col-12 col-md-6 px-0">
                            <div class="d-block mb-md-0 mb-3 h-100">
                                <img src="https://cdn.platex.co.uk/large/Sell-Countach.png" class="d-block w-100 h-100"
                                    style="object-fit: cover;" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="s-spacing"></div>

        <!-- FAQ's -->

    </div>
</div>