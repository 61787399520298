<div>
  <div class="page">
    <div class="page-description-container"></div>
    <div class="content-container">
      <!--  -->
      <div class="pb-3 pt-0 px-1 px-md-3 w-100">
        <div class="border-bottom d-flex align-items-center justify-content-start gap-2 w-100 px-3 py-2">
          <a style="color: #327b8d" class="d-block text-decoration-none" href="/">Home</a>
          <span>/</span>
          <a style="color: #327b8d; white-space: pre" class="text-decoration-none d-block" href="/blogs">Number Plate
            News</a>
          <span class="">/</span>
          <span class="d-block" style="
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            " *ngIf="blog">{{ blog.title }}</span>
        </div>
      </div>
    </div>
    <div class="content-container">
      <div class="side-container p-3 pt-0">
        <pla-side-nav></pla-side-nav>
      </div>
      <div class="center-content-container" *ngIf="blog">
        <div
          class="pla_card mt-0 pt-0 mobile spacing no-top no-card no-left no-bottom no-right position-relative px-2 px-md-4 pb-2 text-black w-100 align-self-start justify-content-start me-auto ms-auto ms-xl-0 me-xl-auto"
          style="max-width: 600px">
          <p class="d-block">{{ blog.created | ordinalDate }}</p>
          <p class="d-block h2 fw-bold text-center mx-auto px-4" style="max-width: 700px">
            {{ blog.title }}
          </p>

          <img src="{{ blog.imageUrl | noCache }}" class="d-block d-md-none mx-auto mx-md-0 w-100 px-4"
            style="max-width: 375px" />
          <!-- <div class="d-block d-md-none mx-auto" style="max-width: 375px">
            <p class="d-block mb-0">by {{ blog.author }}</p>
          </div> -->

          <!-- <div class="d-none d-md-block w-100">
            <p class="d-block mb-0">by {{ blog.author }}</p>
          </div> -->
        </div>

        <div
          class="pla_card mobile spacing no-top no-card no-bottom no-left no-right position-relative px-4 px-md-4 py-4 blog-content me-auto ms-auto ms-xl-0 me-xl-auto"
          style="max-width: 600px">
          <ng-container *ngFor="let paragraph of blog.paragraphs; let index = index">
            <ng-container *ngIf="index == blog.imageParagraphIndex">
              <div class="d-block d-md-flex align-items-start justify-content-between gap-3">
                <div class="flex-grow-1">
                  <p class="text-dark lh-lg fw-light" [innerHTML]="paragraph"></p>
                </div>
                <img src="{{ blog.imageUrl | noCache }}" class="d-none d-md-block mx-auto mx-md-0 mb-3"
                  style="max-width: 300px" />
              </div>
            </ng-container>
            <ng-container *ngIf="index != blog.imageParagraphIndex">
              <p class="fw-light lh-lg text-dark" [innerHTML]="paragraph"></p>
            </ng-container>
          </ng-container>

          <div class="mt-4 d-none">
            <a href="/blogs" style="max-width: 200px" class="d-block mx-auto btn btn-success">All Blogs</a>
          </div>
        </div>

        <div *ngIf="blogFeatured" class="px-2 px-md-0 pt-2 pb-4 me-auto ms-auto ms-xl-0 me-xl-auto"
          style="max-width: 600px">
          <pla-featured-plates [showTitle]="false" [featureType]="blogFeatured" [plateSize]="'featured'"
            [showPrice]="false" [plateClass]="'flat'"></pla-featured-plates>
        </div>
      </div>
    </div>
  </div>
  <div class="bg-light">
    <div class="bg-light py-4 px-2 px-md-4">
      <div class="w-100 mx-auto" style="max-width: 1300px">
        <!-- IN OTHER NEWS -->
        <p class="h3 fw-bolder text-start mb-2">In Other News</p>
        <pla-featured-blogs [blogPosts]="blogPosts"></pla-featured-blogs>
      </div>
    </div>
  </div>
</div>