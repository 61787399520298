<div class="page">
  <div class="content-container extended">
    <div class="ps-5 side-container mb-3">
      <div class="pt-0 position-relative">
        <pla-side-nav></pla-side-nav>
      </div>

      <div class="bg-light">
        <pla-featured-plates
          [featureType]="npFeatured"
          [plateSize]="'smaller'"
          [priceFontSize]="16"
          [maxCount]="10"
          [sortBy]="'price'"
          [plateClass]="'flat'"
          [showIndex]="true"
        ></pla-featured-plates>
      </div>
    </div>
    <div class="center-content-container position-relative">
      <div
        class="pla_card mobile spacing no-top no-card no-left no-right position-relative"
      >
        <div>
          <!-- BREADCRUMBS -->
          <div class="w-100">
            <div
              class="border-bottom d-flex align-items-center justify-content-start gap-2 w-100 px-3 px-lg-0 pb-2 pt-0"
            >
              <a
                style="color: #327b8d"
                class="d-block text-decoration-none"
                [routerLink]="['/']"
                >Home</a
              >
              <span>/</span>
              <a
                style="color: #939393; white-space: pre"
                class="text-decoration-none d-block"
                [routerLink]="['/dateless-search']"
                >Dateless</a
              >
            </div>
          </div>
        </div>

        <pla-banner-image
          [carBanner]="'FERRARI_275'"
          [rawImage]="true"
          [backgroundImage]="true"
          [backgroundClass]="'desktop-mobile-background'"
        >
          <div
            class="d-flex align-items-center justify-content-center flex-column p-4 p-md-4 new-banner-height banner-tint"
          >
            <!-- HEADER TEXT -->
            <div
              class="text-white text-center w-100 align-self-center justify-content-center"
            >
              <p class="h1 mb-1 fw-bold">DATELESS</p>
              <div>
                <p class="fw-light fs-6" style="color: #facf15">
                  Search by style and length
                </p>
              </div>
            </div>
          </div>
        </pla-banner-image>
        <div class="search-input-container d-none">
          <div class="search-area inner">
            <div class="box-container">
              <!-- DEFINE SEARCH CRITERIA BASED ON STYLE AND SIZE -->
              <div
                *ngIf="selectedGroup && selectedOption"
                class="search-selection-container"
              >
                <div class="input-container">
                  <pla-wildcard-character-select
                    (inlineSearch)="searchWithCriteria($event)"
                    (newFormat)="newSearchFormat($event)"
                    [emitOnChange]="true"
                    [prefix]="prefix"
                    [numbers]="numbers"
                    [letters]="letters"
                    [style]="'dateless'"
                    [length]="selectedOption.value"
                    [any_character]="any_character"
                    [no_character]="no_character"
                    [withPadding]="false"
                    [forceSearchButton]="false"
                    [padLeft]="selectedOption.value.length - 1 < 7"
                    [fullWidth]="true"
                    [regenerate]="recalculateInput"
                    [loading]="searching || selecting"
                    [animateOnRecalc]="true"
                  ></pla-wildcard-character-select>
                </div>
              </div>
              <!-- <span class="header">Choose Dateless Style</span> -->
              <div style="margin: 10px auto"></div>
            </div>
          </div>
        </div>
      </div>

      <div
        class="d-none pla_card mobile spacing no-top no-card no-left no-right position-relative px-2 px-md-1 py-0 me-0 me-md-2"
      >
        <!-- CONTENT -->
        <div class="header" style="padding: 0 10px 10px">
          <pla-switch-toggle
            [selectedTabId]="activeSwitch"
            [tabs]="tabs"
            (switchChange)="SwitchChange($event)"
          ></pla-switch-toggle>
          <span
            style="
              display: block;
              margin: auto;
              text-align: center;
              margin-top: 10px;
            "
            >Choose Dateless Style</span
          >
        </div>

        <!-- SWIPER LENGTH OPTIONS -->
        <div class="length-options-container">
          <div class="swiper-container">
            <div class="swiper-nav swiper-prev" (click)="slideDir(false)">
              <mat-icon>chevron_left</mat-icon>
            </div>

            <!-- [navigation]="swiperNavigation" -->
            <swiper
              [pagination]="false"
              [loop]="true"
              [centeredSlides]="true"
              [slidesPerView]="3"
              [allowTouchMove]="!searching && !selecting"
              [enabled]="!searching && !selecting"
              [breakpoints]="swiperBreakpoints"
              #swiperRef
              (init)="swiperInit($event)"
              (slideChange)="onSwipe()"
              class="swiper swiper-border"
            >
              <ng-container *ngFor="let option of optionGroups">
                <ng-template swiperSlide>
                  <div
                    class="length-option swiper-child"
                    [ngClass]="{ selected: selectedGroup.name == option.name }"
                    (click)="slideTo(option)"
                  >
                    <div
                      class="plate-option-container"
                      [ngClass]="{
                        active:
                          letterFirst ||
                          showBoth ||
                          (selectedGroup && selectedGroup.options.length == 1)
                      }"
                    >
                      <pla-plate
                        *ngIf="plateSizeBreakpoint(); else desktopFirstPlate"
                        [rawPlate]="option.options[0]['viewValue']"
                        [xSmallPlate]="true"
                        [mobileSmall]="false"
                        [white]="true"
                        [openModal]="false"
                      ></pla-plate>
                      <ng-template #desktopFirstPlate>
                        <pla-plate
                          [rawPlate]="option.options[0]['viewValue']"
                          [mobileSmall]="false"
                          [smallPlate]="true"
                          [white]="true"
                          [openModal]="false"
                        ></pla-plate>
                      </ng-template>
                    </div>
                    <div
                      class="plate-option-container"
                      [ngClass]="{
                        active:
                          !letterFirst ||
                          showBoth ||
                          (selectedGroup && selectedGroup.options.length == 1)
                      }"
                    >
                      <div
                        *ngIf="option.options.length > 1; else blankPlateSpace"
                      >
                        <pla-plate
                          *ngIf="plateSizeBreakpoint(); else desktopSecondPlate"
                          [rawPlate]="option.options[1]['viewValue']"
                          [mobileSmall]="false"
                          [xSmallPlate]="true"
                          [openModal]="false"
                        ></pla-plate>
                        <ng-template #desktopSecondPlate>
                          <pla-plate
                            [rawPlate]="option.options[1]['viewValue']"
                            [mobileSmall]="false"
                            [smallPlate]="true"
                            [openModal]="false"
                          ></pla-plate>
                        </ng-template>
                      </div>
                    </div>
                    <ng-template #blankPlateSpace>
                      <pla-plate
                        *ngIf="
                          plateSizeBreakpoint();
                          else blankDesktopPlateSpace
                        "
                        [rawPlate]="'_'"
                        style="opacity: 0"
                        [mobileSmall]="false"
                        [xSmallPlate]="true"
                        [openModal]="false"
                      ></pla-plate>
                    </ng-template>
                    <ng-template #blankDesktopPlateSpace>
                      <pla-plate
                        [rawPlate]="'_'"
                        style="opacity: 0"
                        [mobileSmall]="false"
                        [smallPlate]="true"
                        [openModal]="false"
                      ></pla-plate>
                    </ng-template>

                    <div
                      class="label"
                      [ngClass]="{
                        active: false && selectedGroup.name == option.name
                      }"
                    >
                      <span
                        class="text-white"
                        *ngIf="
                          true || selectedGroup.name != option.name;
                          else labelSearchButton
                        "
                        >{{
                          letterFirst || showBoth
                            ? option.viewName
                            : option.reverseViewName
                        }}</span
                      >
                    </div>
                  </div>
                </ng-template>

                <ng-template #labelSearchButton>
                  <span
                    (click)="forceSearch()"
                    class="option-search-button"
                    [ngClass]="{ disabled: searching || selecting }"
                    >{{ searching || selecting ? "SEARCHING" : "SEARCH" }}</span
                  >
                </ng-template>
              </ng-container>
            </swiper>
            <div class="swiper-nav swiper-next" (click)="slideDir(true)">
              <mat-icon>chevron_right</mat-icon>
            </div>
          </div>
          <span style="display: block; margin: auto; text-align: center"
            >Number of Characters</span
          >
        </div>
        <div class="p-2 text-center">
          <button
            mat-raised-button
            class="my-2 px-4 py-0 fs-5 platex-button mx-auto"
            (click)="forceSearch()"
          >
            Search
          </button>
        </div>

        <!-- RESULT SECTION -->
        <div
          *ngIf="canSearch; else infoSection"
          class="pla_card no-card mobile spacing"
        >
          <div *ngIf="!searching || !selecting; else loadingSpinner">
            <!-- SEARCH RESULTS -->
            <div
              *ngIf="
                !showBoth && selectedGroup && selectedGroup.options.length > 1
              "
            >
              <pla-search-result-table
                [searching]="searching || selecting"
                [searchTitle]="searchTitle('dateless')"
                [results]="results.main"
                [resultChange]="main_resultChange"
                [paginationPageSize]="50"
              ></pla-search-result-table>
            </div>

            <!-- SEARCH RESULTS -->
            <div
              *ngIf="
                showBoth || (selectedGroup && selectedGroup.options.length == 1)
              "
            >
              <pla-search-result-table
                [searching]="searching || selecting"
                [searchTitle]="searchTitle('dateless combined')"
                [results]="allResults"
                [resultChange]="all_resultChange"
                [paginationPageSize]="50"
              ></pla-search-result-table>
            </div>
          </div>
        </div>

        <ng-template #loadingSpinner>
          <div class="full spinner-loader">
            <div>
              <mat-progress-spinner
                class="spinner cyan"
                mode="indeterminate"
                [color]="'#327b8d'"
                [diameter]="60"
              ></mat-progress-spinner>
            </div>
          </div>
        </ng-template>

        <ng-template #infoSection>
          <div class="pla_card no-card mobile spacing padding">
            <div class="px-4">
              <div>
                <div class="text-title heading">
                  <h2 class="border-bottom mb-1">
                    <b class="strong">Dateless-By-Size</b>
                  </h2>
                </div>
                <div class="text-section">
                  <div class="text-paragraph">
                    <p>
                      This useful tool searches all the
                      <pla-glossary-term-word
                        [term]="'Dateless Registration'"
                        [text]="'Dateless'"
                      ></pla-glossary-term-word>
                      number plates specifically by the number of characters.
                      Enter letters or numbers into the plate search box to
                      refine the results further or leave blank to review all
                      plates available in that series.
                    </p>
                  </div>
                </div>

                <div class="text-title heading mt-3">
                  <h2 class="border-bottom mb-1">
                    <b class="strong">Did you know...</b>
                  </h2>
                </div>
                <div class="text-section">
                  <div class="text-paragraph">
                    <p class="indent">
                      Single/Single or 1/1 registrations are the most highly
                      sought-after registrations and command incredible prices.
                      The pinnacle of these are the number ‘1’ registration of
                      which there are only 46 combinations available. The most
                      expensive 1/1 registration currently available is
                      <pla-plate
                        class="d-inline-block"
                        [rawPlate]="'F 1'"
                        [useCustom]="true"
                        [customSize]="9"
                        [customFontSize]="17"
                        [inlineText]="true"
                      ></pla-plate>
                    </p>
                  </div>
                </div>

                <div class="text-section mt-3">
                  <div class="text-paragraph">
                    <div class="faq-item">
                      <mat-icon>help_outline</mat-icon>
                      <h4>Can I put a Dateless registration on my vehicle?</h4>
                    </div>
                    <p class="indent">
                      You are not allowed to make a vehicle appear younger by
                      transferring a more recent registration. However,
                      <pla-glossary-term-word
                        [term]="'Dateless Registration'"
                        [text]="'Dateless'"
                      ></pla-glossary-term-word>
                      registrations have no date indicator so they can be
                      transferred to any age vehicle.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <!-- FEATURED LISTING -->
            <div class="featured-container mt-5">
              <div class="px-4">
                <h2 class="border-bottom mb-1">Featured Dateless</h2>
              </div>
              <div>
                <pla-featured-plates
                  [featureType]="datelessFeatured"
                  [showTitle]="false"
                ></pla-featured-plates>
              </div>
            </div>
          </div>
        </ng-template>
      </div>
    </div>
  </div>
</div>
