<div>
  <div *ngIf="!registrationHistory.loading">
    <div class="chart-container" *ngIf="hasSoldData(2, true)">
      <pla-chart-price-history [data]="registrationHistoryPoints"></pla-chart-price-history>
    </div>
    <div *ngIf="!hasSoldData(2, true)">
      <p class="text-center">
        This plate doesnt have enough history to generate a chart, see below to
        see price points available.
      </p>
    </div>
    <pla-sold-data-table [data]="registrationHistory.data"></pla-sold-data-table>
  </div>
</div>