<div class="position-relative text-dark w-100 hover-interact pointer">
  <div
    class="w-100 overflow-hidden d-flex align-items-center justify-content-center gap-2 px-2 py-2 {{ baseClass }} {{ (config && open) ? openedClass : closedClass }}"
    style="cursor: pointer;" (click)="ToggleOpen()">
    <small class="fs-6 text-decoration-none text-dark text-center d-block my-0">{{ selectedValue.view || config.title
      }}</small>
    <mat-icon *ngIf="config.icon" class="small">{{config.icon}}</mat-icon>
  </div>
  <!-- OPTIONS -->
  <div *ngIf="config && open"
    class="{{ optionClass }} w-100 position-absolute start-0 end-0 bottom-0 translate-bottom bg-light shadow-sm border-start border-bottom border-end border-dark"
    style="z-index: 100;">
    <!-- <div class="border-top px-2 py-2" style="cursor: pointer" (click)="ToggleOpen()">
      <p class="mb-0 text-center" style="color: #afafaf;">Select an Option</p>
    </div> -->

    <ng-container *ngFor="let option of VisibleOptions(config.options)">
      <div class="border-top px-2 py-2" style="cursor: pointer" [class.bg-white]="option.value == selectedValue.value"
        (click)="SelectValue(option)">
        <p class="mb-0 text-center" [style.color]="option.value == selectedValue.value ? '#327b8d' : '#afafaf'">
          {{ option.view }}
        </p>
      </div>
    </ng-container>
  </div>
</div>