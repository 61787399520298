<div
  *ngIf="data"
  style="
    width: 100%;
    height: 100%;
    min-height: 100px;
    min-width: 100px;
    background-color: white;
  "
>
  <img style="display: block; height: 100%" [src]="data.b64" />
</div>
