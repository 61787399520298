import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserHistory } from 'src/app/models/userHistory';
import { UserService } from 'src/app/services/user-service';

@Component({
  selector: 'pla-user-history',
  templateUrl: './user-history.component.html',
  styleUrls: ['./user-history.component.scss']
})
export class UserHistoryComponent implements OnInit {

  @Input() public type: 'search' | 'compare';
  @Input() public title: string;
  public loading: boolean = true;
  public history: UserHistory[];

  constructor(private userService: UserService, private router: Router) {
  }

  ngOnInit(): void {
    this.loading = true;
    this.history = this.userService.getHistory(this.type).splice(0, 10);
    this.loading = false;
  }

  public ClickHandle(uh: UserHistory): void {
    if (uh.type === 'compare') {
      this.router.navigate([`/compare/${uh.registration.replace(' ', '')}`]);
    } else if (uh.type === 'search') {
      this.router.navigate([`/search-results/${uh.term}`]);
    }
  }

}
