<div class="option-selection-page" *ngIf="registration">
  <div class="preview-container">
    <pla-plate
      [rawPlate]="registration"
      [mobileSmall]="false"
      [xLargePlate]="true"
      [noMargin]="false"
      [redraw]="redrawPlate"
    ></pla-plate>
    <pla-plate
      [rawPlate]="registration"
      [mobileSmall]="false"
      [white]="true"
      [xLargePlate]="true"
      [noMargin]="false"
      [redraw]="redrawPlate"
    ></pla-plate>
  </div>

  <div class="options-container desktop">
    <div class="options-row">
      <div class="options-column">
        <div class="option-item-header">
          <label>SIZE</label>
        </div>
        <div class="size-items">
          <div
            class="option-item"
            [ngClass]="{
              active: item.id == selectedSize?.id,
              hide:
                (item.id != '0' && selectedSize != null) ||
                (item.id == '0' && selectedSize != null)
            }"
            *ngFor="let item of sizeOptions"
            (click)="selectSize(item)"
          >
            <img [src]="item.icon" />
            <span>{{ item.name }}</span>
          </div>
        </div>
        <div class="price">+£0.00</div>
      </div>
      <div class="options-column">
        <div class="option-item-header">
          <label>FONT</label>
          <div class="option-item" style="max-width: 70px">
            <img src="/assets/customiser/FONT_1.png" />
          </div>
        </div>
        <div class="font-items">
          <div
            class="option-item"
            [ngClass]="{
              active: item.id == selectedFont?.id,
              hide:
                (item.id != '0' && selectedFont != null) ||
                (item.id == '0' && selectedFont != null)
            }"
            style="max-width: 35px"
            *ngFor="let item of fontOptions"
            (click)="selectFont(item)"
          >
            <span>{{ item.name }}</span>
            <img [src]="item.icon" />
          </div>
        </div>
        <div class="price">+£0.00</div>
      </div>
      <div class="options-column">
        <div class="option-item-header">
          <label>BORDER</label>
          <div class="option-item" style="max-width: 50px">
            <img src="/assets/customiser/BORDER.png" />
          </div>
        </div>
        <div class="border-items">
          <div
            class="option-item"
            [ngClass]="{
              active: item.id == selectedBorder?.id,
              hide:
                (item.id != '0' && selectedBorder != null) ||
                (item.id == '0' && selectedBorder != null)
            }"
            *ngFor="let item of borderOptions"
            (click)="selectBorder(item)"
          >
            <img [src]="item.icon" />
          </div>
        </div>
        <div class="price">+£0.00</div>
      </div>
      <div class="options-column">
        <div class="option-item-header">
          <label>FLAG</label>
          <div class="option-item" style="max-width: 60px">
            <img src="/assets/customiser/FLAG.png" />
          </div>
        </div>
        <div class="flag-items">
          <div
            class="option-item"
            [ngClass]="{
              active: item.id == selectedFlag?.id,
              hide:
                (item.id != '0' && selectedFlag != null) ||
                (item.id == '0' && selectedFlag != null)
            }"
            *ngFor="let item of flagOptions"
            (click)="selectFlag(item)"
          >
            <img [src]="item.icon" />
          </div>
        </div>
        <div class="price">+£0.00</div>
      </div>
    </div>
  </div>

  <div class="mobile-tabs">
    <ul class="tabs-list">
      <li
        [ngClass]="{ active: seletedTab == 1 }"
        class="active"
        (click)="displayTab(1)"
      >
        <img src="/assets/customiser/SIZE.png" /> <span>SIZE</span>
      </li>
      <li [ngClass]="{ active: seletedTab == 2 }" (click)="displayTab(2)">
        <img src="/assets/customiser/FONT_1.png" /> <span>FONT</span>
      </li>
      <li [ngClass]="{ active: seletedTab == 3 }" (click)="displayTab(3)">
        <img src="/assets/customiser/BORDER.png" /> <span>BORDER</span>
      </li>
      <li [ngClass]="{ active: seletedTab == 4 }" (click)="displayTab(4)">
        <img src="/assets/customiser/FLAG.png" /> <span>FLAG</span>
      </li>
    </ul>
  </div>

  <div class="options-container mobile">
    <div class="options-row">
      <div class="options-column active" *ngIf="seletedTab == 1">
        <div class="option-item-header">
          <label>SIZE</label>
        </div>
        <div class="size-items">
          <div
            class="option-item"
            [ngClass]="{
              active: item.id == selectedSize?.id,
              hide:
                (item.id != '0' && selectedSize != null) ||
                (item.id == '0' && selectedSize != null)
            }"
            *ngFor="let item of sizeOptions"
            (click)="selectSize(item)"
          >
            <img [src]="item.icon" />
            <span>{{ item.name }}</span>
          </div>
        </div>
        <div class="price">+£0.00</div>
      </div>
      <div class="options-column" *ngIf="seletedTab == 2">
        <div class="option-item-header">
          <label>FONT</label>
          <div class="option-item">
            <img src="/assets/customiser/FONT_1.png" />
          </div>
        </div>
        <div class="font-items">
          <div
            class="option-item"
            [ngClass]="{
              active: item.id == selectedFont?.id,
              hide:
                (item.id != '0' && selectedFont != null) ||
                (item.id == '0' && selectedFont != null)
            }"
            *ngFor="let item of fontOptions"
            (click)="selectFont(item)"
          >
            <span>{{ item.name }}</span>
            <img [src]="item.icon" />
          </div>
        </div>
        <div class="price">+£0.00</div>
      </div>
      <div class="options-column" *ngIf="seletedTab == 3">
        <div class="option-item-header">
          <label>BORDER</label>
          <div class="option-item">
            <img src="/assets/customiser/BORDER.png" />
          </div>
        </div>
        <div class="border-items">
          <div
            class="option-item"
            [ngClass]="{
              active: item.id == selectedBorder?.id,
              hide:
                (item.id != '0' && selectedBorder != null) ||
                (item.id == '0' && selectedBorder != null)
            }"
            *ngFor="let item of borderOptions"
            (click)="selectBorder(item)"
          >
            <img [src]="item.icon" />
          </div>
        </div>
        <div class="price">+£0.00</div>
      </div>
      <div class="options-column" *ngIf="seletedTab == 4">
        <div class="option-item-header">
          <label>FLAG</label>
          <div class="option-item">
            <img src="/assets/customiser/FLAG.png" />
          </div>
        </div>
        <div class="flag-items">
          <div
            class="option-item"
            [ngClass]="{
              active: item.id == selectedFlag?.id,
              hide:
                (item.id != '0' && selectedFlag != null) ||
                (item.id == '0' && selectedFlag != null)
            }"
            *ngFor="let item of flagOptions"
            (click)="selectFlag(item)"
          >
            <img [src]="item.icon" />
          </div>
        </div>
        <div class="price">+£0.00</div>
      </div>
    </div>
  </div>
</div>
