import { ViewportScroller } from '@angular/common';
import {
  Component,
  Directive,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
} from '@angular/core';

export class StickyLink {
  constructor(
    public viewName: string,
    public anchor: string,
    public active: boolean
  ) {}
}

export class StickyConfig {
  constructor(
    public links: StickyLink[] = [],
    public reScroll: EventEmitter<void> = new EventEmitter<void>()
  ) {}
}

@Component({
  selector: 'pla-sticky-tabs',
  templateUrl: './sticky-tabs.component.html',
  styleUrls: ['./sticky-tabs.component.scss'],
})
export class StickyTabsComponent implements OnInit {
  @Input() public config: StickyConfig = new StickyConfig();
  public links: StickyLink[] = [];

  constructor(private viewportScroller: ViewportScroller) {}

  ngOnInit(): void {
    this.links = this.config.links;
    this.config.reScroll.subscribe(() => {
      var link = this.links.find((l) => l.active);
      if (link === undefined || link === null) return;
      this.viewportScroller.scrollToAnchor(link.anchor);
    });
  }

  public ChangeLink(link: StickyLink): void {
    this.links = this.links.map((l) => {
      l.active = l.anchor == link.anchor;
      return l;
    });

    this.viewportScroller.scrollToAnchor(link.anchor);
  }
}
