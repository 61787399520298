<div class="component-container">
  <!-- HEADER FILTERS -->
  <div class="header-container" *ngIf="false && showHeader">
    <h2>Compare Prices With:</h2>

    <div class="option">
      <mat-checkbox
        [(ngModel)]="withTransfer"
        (change)="optionChangeHandler($event)"
      >
        <div class="checkbox-detail">
          <h3>Transfer service</h3>
          <span class="special-text">82% of customers choose this!</span>
        </div>
      </mat-checkbox>
    </div>

    <div class="option">
      <mat-checkbox
        [(ngModel)]="withPerspex"
        (change)="optionChangeHandler($event)"
      >
        <div class="checkbox-detail">
          <h3>Perspex number plates</h3>
          <span class="special-text">94% of customers choose this!</span>
        </div>
      </mat-checkbox>
    </div>
  </div>

  <!-- <div style="margin-top: 20px"></div> -->

  <pla-signed-up-content
    [customTrigger]="customSignUpTrigger"
    [active]="false"
    [requesting]="'compare-results'"
  >
    <div lockedContent class="text-center mt-3 px-2">
      <p class="mb-0 fs-5">Login To View</p>
      <p>
        Sign up to use Price Comparison and all our other great features. It's
        fast and free!
      </p>
    </div>
    <div unlockedContent>
      <div *ngIf="lastChecked" class="px-1 px-lg-0">
        Details Last Checked {{ lastChecked | date : "dd/MM/yy" }}
      </div>
      <!-- MOBILE RESULTS -->
      <div class="results-container compare-mobile-only">
        <ng-container *ngIf="results; else loadingSpinner">
          <ng-container *ngFor="let compResult of results">
            <div class="mobile-row-container" *ngIf="canShowRow(compResult)">
              <div [ngClass]="{ disabled: !compResult.calculated }">
                <div class="mobile-row">
                  <!-- SELLER -->
                  <div>
                    <div
                      *ngIf="
                        compResult.site == 'platex' || compResult.platexListing;
                        else otherSiteName
                      "
                    >
                      <pla-platex-direct></pla-platex-direct>
                    </div>
                    <ng-template #otherSiteName>
                      <p class="siteName">
                        {{ compResult.viewName
                        }}<span class="special-text">{{
                          compResult.domainSuffix
                        }}</span>
                      </p>
                    </ng-template>
                  </div>

                  <!-- PRICE -->
                  <div>
                    <p
                      class="price"
                      *ngIf="compResult.calculated; else noPrice"
                    >
                      <ng-container
                        *ngIf="compResult.total < 1000000; else showRawPence"
                      >
                        {{ compResult.total | currency : "GBP" }}
                      </ng-container>
                      <ng-template #showRawPence>
                        {{
                          compResult.total
                            | currency : "GBP" : "symbol" : "1.0-0"
                        }}
                      </ng-template>
                    </p>
                  </div>
                </div>

                <!-- BOTTOM HALF -->
                <div class="mobile-row padded">
                  <!-- TRUST PILOT -->
                  <div *ngIf="compResult.platexListing">
                    <span>Platex User Listing</span>
                  </div>
                  <div
                    *ngIf="!compResult.platexListing"
                    class="trust-pilot-details"
                    (click)="openTrustPilotHandler(compResult)"
                  >
                    <div class="image-container">
                      <img
                        src="{{ trustPilotScoreImageGenerator(compResult) }}"
                      />
                    </div>
                    <span class="tp-detail"
                      >{{
                        compResult.trustPilotRatingCount | number : "1.0-0"
                      }}
                      reviews</span
                    >
                  </div>

                  <!-- BUY BUTTON -->
                  <div>
                    <button
                      *ngIf="compResult.platexListing"
                      class="cyan-button max-content button-short"
                      (click)="platexMessage(compResult)"
                      mat-raised-button
                    >
                      <span>Message</span>
                    </button>

                    <button
                      *ngIf="
                        !compResult.platexListing &&
                          compResult.site == 'platex';
                        else otherSupplier
                      "
                      class="cyan-button max-content button-short"
                      (click)="openCompareSiteHandler(compResult)"
                      mat-raised-button
                    >
                      <span>Buy</span>
                    </button>
                    <ng-template #otherSupplier>
                      <button
                        *ngIf="!compResult.platexListing"
                        class="navy-button max-content button-short"
                        (click)="openCompareSiteHandler(compResult)"
                        mat-raised-button
                      >
                        <span>Go To Dealer</span>
                      </button>
                    </ng-template>
                  </div>
                </div>

                <div
                  class="mobile-row bottom"
                  *ngIf="!compResult.platexListing"
                >
                  <div class="mobile-extra-detail">
                    <span>Transfer Service</span>
                    <ng-container
                      *ngIf="compResult.transferService >= 0; else cross"
                    >
                      <ng-container *ngTemplateOutlet="tick"></ng-container>
                    </ng-container>
                  </div>

                  <div class="mobile-extra-detail">
                    <span>Perspex Plates</span>
                    <ng-container
                      *ngIf="compResult.physicalPlatePrice >= 0; else cross"
                    >
                      <ng-container *ngTemplateOutlet="tick"></ng-container>
                    </ng-container>
                  </div>

                  <div class="mobile-extra-detail">
                    <span>Finance</span>
                    <ng-container *ngIf="compResult.finance; else cross">
                      <ng-container *ngTemplateOutlet="tick"></ng-container>
                    </ng-container>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
        </ng-container>
      </div>

      <!-- DESKTOP RESULTS -->
      <div class="results-container compare-desktop-only">
        <table *ngIf="results; else loadingSpinner">
          <!-- headers -->
          <tr>
            <th>
              <h4 style="text-align: left">Supplier</h4>
            </th>
            <th>
              <h4>Trustpilot Rating</h4>
            </th>
            <th>
              <h4>Finance</h4>
            </th>
            <th>
              <h4>Price</h4>
            </th>
            <th class="wide-field"></th>
          </tr>

          <!-- dynamic -->
          <ng-container *ngFor="let compResult of results">
            <tr
              class="compRow"
              [ngClass]="{ disabled: !compResult.calculated }"
              *ngIf="canShowRow(compResult)"
            >
              <!-- SITE NAME -->
              <td>
                <div
                  *ngIf="
                    compResult.site == 'platex' || compResult.platexListing;
                    else otherSiteName
                  "
                >
                  <pla-platex-direct [align]="'left'"></pla-platex-direct>
                </div>
                <ng-template #otherSiteName>
                  <p class="siteName">
                    {{ compResult.viewName
                    }}<span class="special-text">{{
                      compResult.domainSuffix
                    }}</span>
                  </p>
                </ng-template>
              </td>

              <!-- TRUST PILOT -->
              <td>
                <div>
                  <div *ngIf="compResult.platexListing">
                    <span>Platex User Listing</span>
                  </div>
                  <div
                    *ngIf="!compResult.platexListing"
                    class="trust-pilot-details"
                    (click)="openTrustPilotHandler(compResult)"
                  >
                    <div class="image-container">
                      <img
                        src="{{ trustPilotScoreImageGenerator(compResult) }}"
                      />
                    </div>
                    <span class="tp-detail"
                      >{{
                        compResult.trustPilotRatingCount | number : "1.0-0"
                      }}
                      reviews on Trustpilot</span
                    >
                  </div>
                </div>
              </td>

              <!-- OFFERS FINANCE -->
              <td>
                <div class="desktop-view">
                  <div *ngIf="compResult.platexListing" class="mid-container">
                    <mat-icon>close</mat-icon>
                  </div>

                  <ng-container *ngIf="!compResult.platexListing">
                    <ng-container
                      *ngIf="
                        compResult.finance && compResult.calculated;
                        else cross
                      "
                    >
                      <ng-container *ngTemplateOutlet="tick"></ng-container>
                    </ng-container>
                  </ng-container>
                </div>
                <div class="mobile-view">
                  <div *ngIf="compResult.platexListing" class="mid-container">
                    <mat-icon>close</mat-icon>
                  </div>

                  <ng-container *ngIf="!compResult.platexListing">
                    <ng-container
                      *ngIf="
                        compResult.finance && compResult.calculated;
                        else noFinance
                      "
                    >
                      <ng-container
                        *ngTemplateOutlet="withFinance"
                      ></ng-container>
                    </ng-container>
                  </ng-container>
                </div>
              </td>

              <!-- PRICE -->
              <td>
                <div>
                  <p
                    class="price desktop-view"
                    *ngIf="compResult.calculated; else noPrice"
                  >
                    {{ compResult.total | currency : "GBP" }}
                  </p>
                </div>
              </td>

              <!-- ACTION -->
              <td>
                <div
                  *ngIf="compResult.status == 'FOUND'"
                  class="compare-action-button"
                >
                  <button
                    *ngIf="compResult.platexListing"
                    class="cyan-button"
                    (click)="platexMessage(compResult)"
                    mat-raised-button
                  >
                    <span class="desktop-view">Message</span>
                    <span
                      class="price mobile-view"
                      *ngIf="compResult.calculated; else noPrice"
                    >
                      Message
                    </span>
                  </button>

                  <button
                    *ngIf="
                      !compResult.platexListing && compResult.site == 'platex';
                      else otherSupplier
                    "
                    class="cyan-button"
                    (click)="openCompareSiteHandler(compResult)"
                    mat-raised-button
                  >
                    <span class="desktop-view">Buy</span>
                    <span
                      class="price mobile-view"
                      *ngIf="compResult.calculated; else noPrice"
                    >
                      Buy {{ compResult.total | currency : "GBP" }}
                    </span>
                  </button>
                  <ng-template #otherSupplier>
                    <button
                      *ngIf="!compResult.platexListing"
                      class="navy-button"
                      (click)="openCompareSiteHandler(compResult)"
                      mat-raised-button
                    >
                      <span class="desktop-view">Go To Dealer</span>
                      <span
                        class="price mobile-view"
                        *ngIf="compResult.calculated; else noPrice"
                      >
                        Go To Dealer {{ compResult.total | currency : "GBP" }}
                      </span>
                    </button>
                  </ng-template>
                </div>
              </td>
            </tr>
          </ng-container>
        </table>
      </div>
    </div>
  </pla-signed-up-content>
</div>

<ng-template #loadingSpinner>
  <div class="full spinner-loader">
    <div>
      <mat-progress-spinner
        class="spinner cyan"
        mode="indeterminate"
        [color]="'#327b8d'"
        [diameter]="60"
      ></mat-progress-spinner>
    </div>
    <span>Loading Comparisons...</span>
  </div>
</ng-template>
<ng-template #noPrice>
  <p class="price">N/A</p>
</ng-template>
<ng-template #tick>
  <div class="tick-container">
    <mat-icon>done</mat-icon>
  </div>
</ng-template>
<ng-template #cross>
  <div class="cross-container">
    <mat-icon>close</mat-icon>
  </div>
</ng-template>
<ng-template #financeIcon>
  <div class="tick-container">
    <mat-icon>event_repeat</mat-icon>
  </div>
</ng-template>
<ng-template #noFinanceIcon>
  <div class="cross-container greyed">
    <mat-icon>event_busy</mat-icon>
  </div>
</ng-template>
<ng-template #withFinance>
  <div>
    <span>Finance Available</span>
  </div>
</ng-template>
<ng-template #noFinance>
  <div>
    <span>No Finance</span>
  </div>
</ng-template>
