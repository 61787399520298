<div class="container-fluid row px-0 mx-auto">
  <div class="col-12 col-md-6">
    <pla-dynamic-charts
      *ngIf="false"
      [chartType]="'price-index'"
      [data]=""
    ></pla-dynamic-charts>
  </div>
  <div class="col-12 col-md-6">
    <pla-chart-price-data *ngIf="priceData" [data]="priceData"></pla-chart-price-data>
  </div>
</div>
