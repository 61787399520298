<div id="sticky-bby" class="position-sticky bg-white" style="top: 54px; z-index: 90;">
  <div class="d-flex align-items-center justify-content-start border-bottom gap-3 gap-md-3 py-2 px-2 px-md-1"
    style="overflow-x: auto;">
    <ng-container *ngFor="let link of links">
      <div class="flex-shrink-0 px-3 px-md-3 py-2 rounded-pill hover-interact pointer" [style.background]="link.active ? '#0A6077' : '#dee2e6'"
        style="cursor: pointer;" (click)="ChangeLink(link)">
        <small class="my-0 d-block lh-sm {{
          link.active ? 'text-white fs-md-14' : 'text-dark fs-md-14'
        }}">{{ link.viewName }}</small>
      </div>
    </ng-container>
  </div>
</div>