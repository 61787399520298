<div class="page pt-lg-4">
  <div class="content-container extended">
    <div class="ps-5 side-container mb-3">
      <div class="pt-0 position-relative">
        <pla-side-nav></pla-side-nav>
      </div>

      <div class="bg-light">
        <pla-featured-plates [featureType]="rsFeatured" [plateSize]="'smaller'" [priceFontSize]="16" [maxCount]="10"
          [sortBy]="'price'" [plateClass]="'flat'"></pla-featured-plates>
      </div>
    </div>
    <div class="center-content-container position-relative">
      <div
        class="pla_card mobile spacing no-top no-card no-left no-right mb-position-sticky start-0 bg-white end-0 my-0 pt-2 pt-lg-0"
        style="top: 55px !important; z-index: 10000;">
        <div>
          <!-- BREADCRUMBS -->
          <div class="w-100">
            <div
              class="border-bottom d-flex align-items-center justify-content-start gap-2 w-100 px-3 px-lg-0 pb-2 pt-0">
              <a style="color: #327b8d" class="d-block text-decoration-none" [routerLink]="['/']">Home</a>
              <span>/</span>
              <a style="color: #939393; white-space: pre" class="text-decoration-none d-block"
                [routerLink]="['/plate-viewer']">Plate Viewer</a>
              <span>{{lastAlphabetToView}}</span>
            </div>
          </div>
        </div>

        <!-- IMAGE -->
        <div class="w-100 p-2 m-auto">
          <img class="w-auto d-block mx-auto" style="max-height: 65vh; aspect-ratio: auto; max-width: 100%"
            *ngIf="selectedImage" [src]="selectedImage.image" />
        </div>

      </div>

      <div
        class="pla_card mobile spacing no-top no-card no-left no-right position-relative px-2 px-md-1 pb-0 pt-3 me-0 me-md-2">

        <!-- INPUT -->
        <div>
          <div class="m-auto d-flex align-items-center justify-content-center">
            <div class="text-input-container text-plate-input w-content">
              <div class="input-container plate-background w-content flex-shrink">
                <input style="width: 10ch" class="plate-font" matInput required [(ngModel)]="registrationString"
                  placeholder="PLATE" type="text" />
              </div>
            </div>
            <button mat-flat-button (click)="UpdateImage()" class="platex-button ms-1 h-100">
              Update
            </button>
          </div>

          <div class="text-center mt-3 px-2">
            <h4 class="fs-4">The Curated Collection</h4>
            <p>
              Visualise your perfect private plate on a range of exceptional
              vehicles with our plate visualiser
            </p>
          </div>
        </div>

        <!-- IMAGES -->
        <div>
          <ng-container *ngFor="let letter of alphabet.split('')">
            <div inViewport (inViewportAction)="LetterInView($event, letter)" [inViewportOptions]="{ threshold: 400 }"
              class="mb-5 px-3 mx-auto" *ngIf="ImagesForLetter(letter).length > 0" style="max-width: 1000px">
              <div class="border-bottom py-2 mb-3">
                <h2 class="display-6 d-block w-100 text-start ms-0 fw-bold">
                  {{ letter }}
                </h2>
              </div>
              <div class="m-auto mt-2 mb-5 container-fluid px-0 w-100">
                <div class="row px-0">
                  <ng-container *ngFor="let config of ImagesForLetter(letter)">
                    <div class="col-md-4 col-6" style="cursor: pointer;" (click)="updateConfig(config)">
                      <img class="d-block w-100" loading="lazy" alt="{{ config.carName }}"
                        [src]="config.builtConfig.config.imageInfo.path" />
                      <p class="m-0 text-center w-100">{{ config.carName }}</p>
                    </div>
                  </ng-container>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>

  <div class="content-container" *ngIf="false">
    <pla-banner-image class="w-100" [rawImage]="true" [backgroundImage]="true" [carBanner]="'AVENTADOR_S_FOOTER'"
      [backgroundClass]="'w-100 desktop-mobile-bottom'">
      <div class="d-flex align-items-stretch justify-content-end new-banner-height banner-tint">
        <div
          class="w-75 w-md-100 p-4 ps-5 ps-md-4 d-flex align-items-center justify-content-center ltr_gradient md-no-gradient">
          <div class="d-flex align-items-center justify-content-center flex-column ms-auto me-0"
            style="max-width: 450px">
            <p class="fs-5 text-white">
              With the entire market of registrations on one single portal,
              industry leading optimised search facilities and even price
              comparison it's no wonder buyers are choosing PlateX to find their
              perfect plate.
            </p>

            <div class="align-self-end mt-4">
              <button class="ms-auto me-0 turquoise-button" mat-flat-button>
                Begin Free Trial
              </button>
            </div>
          </div>
        </div>
      </div>
    </pla-banner-image>
  </div>
</div>