<div class="page pt-2 pt-md-4">
    <div class="content-container extended">
        <div class="ps-5 side-container mb-3">
            <div class="pt-0 position-relative">
                <pla-side-nav></pla-side-nav>
            </div>

            <div class="bg-light">
                <!-- <pla-featured-plates [featureType]="rsFeatured" [plateSize]="'smaller'" [priceFontSize]="16"
                    [maxCount]="10" [sortBy]="'price'" [plateClass]="'flat'" [showIndex]="false"></pla-featured-plates> -->
            </div>
        </div>
        <div class="center-content-container position-relative">
            <div class="pla_card mobile spacing no-top no-card no-left no-right position-relative">
                <div>
                    <!-- BREADCRUMBS -->
                    <div class="w-100">
                        <div
                            class="border-bottom d-flex align-items-center justify-content-start gap-2 w-100 px-3 px-lg-0 pb-2 pt-0">
                            <a style="color: #327b8d" class="d-block text-decoration-none" [routerLink]="['/']">Home</a>
                            <span>/</span>
                            <a style="white-space: pre; color: #327b8d" class="text-decoration-none d-block"
                                [routerLink]="['/articles']">Resources</a>
                            <span>/</span>
                            <span style="color: #939393; white-space: pre" class="text-decoration-none d-block">Suffix
                                Number Plates</span>
                        </div>
                    </div>
                </div>
            </div>

            <div
                class="pla_card mobile spacing no-top no-card no-left no-right position-relative px-0 px-md-1 py-0 me-0">
                <div class="px-0 px-md-2">

                    <div class="mx-3 mx-md-0">

                        <div class="mt-4 mb-4 border-bottom border-2" style="border-color: #3791A8 !important;">
                            <p class="fs-mb-16 fs-md-18 fw-bold text-start mx-0">What are Suffix Number Plates?</p>
                        </div>

                        <div class="mb-4">
                            <img src="https://cdn.platex.co.uk/pages/articles/dorsia_countach.png"
                                style="max-width: 500px" class="mx-auto d-block w-100" />
                        </div>

                        <div>
                            <p class="fs-md-14 fs-mb-14 text-dark d-block mb-4">
                                Suffix number plates were introduced in the UK in 1963 to address the growing number of
                                vehicles and the need for more registration combinations. These plates feature a year
                                identifier at the end, distinguishing them from earlier dateless registrations.
                            </p>

                            <p class="fs-md-14 fs-mb-14 text-dark d-block mb-2">
                                Format:
                            </p>

                            <p class="fs-md-14 fs-mb-14 text-dark d-block mb-4">
                                Three letters, up to three numbers, followed by a single letter indicating the year of
                                registration (e.g., ABC 123A).
                                Year Identifier: The suffix letter changes annually, providing a clear indication of the
                                vehicle's age.
                            </p>

                            <p class="fs-md-14 fs-mb-14 text-dark d-block mb-2">
                                A Brief History
                            </p>

                            <p class="fs-md-14 fs-mb-14 text-dark d-block mb-4">
                                The suffix number plate system was introduced on 1st January 1963, starting with the
                                letter 'A'. The system provided a way to quickly identify the age of a vehicle,
                                addressing the limitations of the previous dateless system. Each year, a new suffix
                                letter was introduced, continuing until 1982 when the system was replaced by the prefix
                                system.
                            </p>
                        </div>
                    </div>

                    <div style="height: 50px;"></div>

                    <div class="mx-3 mx-md-0">

                        <div class="mt-4 mb-4 border-bottom border-2" style="border-color: #3791A8 !important;">
                            <p class="fs-mb-16 fs-md-18 fw-bold text-start mx-0">Can You Use A Suffix Plate on Any
                                Vehicle</p>
                        </div>

                        <div>
                            <p class="fs-md-14 fs-mb-14 text-dark d-block mb-4">
                                Suffix plates do have a year identifier so they can only be added to a vehicle that was
                                manufacture in the same registration year or more recently. This is because it is
                                illegal to make a vehicle appear more new than it is.
                                There is information about the year of registration for each registration on the
                                individual plate information page along with price history where available.
                            </p>
                        </div>

                        <div class="mt-4 mb-4">
                            <img src="https://cdn.platex.co.uk/pages/articles/suffix_format.png"
                                style="max-width: 250px" class="mx-auto d-block w-100" />
                        </div>
                    </div>

                    <div style="height: 50px;"></div>

                    <div class="mx-3 mx-md-0">

                        <div class="mt-4 mb-4 border-bottom border-2" style="border-color: #3791A8 !important;">
                            <p class="fs-mb-16 fs-md-18 fw-bold text-start mx-0">Where to Find Suffix Number Plates</p>
                        </div>

                        <div>
                            <p class="fs-md-14 fs-mb-14 text-dark d-block mb-4">
                                All available suffix plates that match your searched word will be displayed when using
                                our super search. Alternatively you can see them displayed separately when using the
                                special <a href="/initials-search" class="text-decoration-underline"
                                    style="color: #3791A8;">initials search</a> or you can Build-Your-Own suffix
                                registration here;
                            </p>
                        </div>

                        <div class="mb-4">
                            <pla-byo-search-page [style]="'current'" [searchDefault]="false"
                                [inlineSearch]="true"></pla-byo-search-page>
                        </div>

                        <div>
                            <p class="fs-md-14 fs-mb-14 text-dark d-block mb-2">To start;</p>
                            <p class="fs-md-14 fs-mb-14 text-dark d-block mb-4">Enter the word or initials and leave the
                                remaining characters with a “?” to be freely allocated. You will notice that depending
                                on the style there location of the required numbers changes.</p>
                            <p class="fs-md-14 fs-mb-14 text-dark d-block mb-4">Note. Some letters are not available for
                                some series; I, O, Q Z are not available for the final letter of Suffix. Only certain
                                numbers have been released</p>
                        </div>
                    </div>

                    <div style="height: 50px;"></div>


                    <div class="mx-3 mx-md-0">

                        <div class="mt-4 mb-4 border-bottom border-2" style="border-color: #3791A8 !important;">
                            <p class="fs-mb-16 fs-md-18 fw-bold text-start mx-0">The Role of PlateX</p>
                        </div>

                        <div>
                            <p class="fs-md-14 fs-mb-14 text-dark d-block mb-4">
                                PlateX.co.uk is revolutionising the private plate market by improving transparency and
                                providing a free price comparison service. Similar to how Rightmove and Zoopla operate
                                in the property market, PlateX aggregates listings from multiple dealers, offering a
                                comprehensive view of the market.
                            </p>
                        </div>

                        <div>
                            <p class="fs-md-14 fs-mb-14 text-dark d-block mb-4">
                                Key Features of PlateX:
                            </p>
                        </div>

                        <div>
                            <p class="fs-md-14 fs-mb-14 text-dark d-block mb-4">
                                Comprehensive Listings: Aggregates private plate listings from various dealers.
                            </p>
                        </div>

                        <div>
                            <p class="fs-md-14 fs-mb-14 text-dark d-block mb-4">
                                Price Comparison: PlateCompare is a free service that compares prices across different
                                platforms, helping users find the best deals and reducing price discrepancies.
                            </p>
                        </div>

                        <div>
                            <p class="fs-md-14 fs-mb-14 text-dark d-block mb-4">
                                User-Friendly Interface: Simplifies the process of searching, buying, and selling
                                private plates.
                            </p>
                        </div>

                        <div>
                            <p class="fs-md-14 fs-mb-14 text-dark d-block mb-4">
                                By providing these services, PlateX.co.uk enhances transparency and liquidity in the
                                private plate market, benefiting both consumers and dealers.
                            </p>
                        </div>
                    </div>

                    <div style="height: 50px;"></div>
                </div>
            </div>
        </div>
    </div>

    <div class="bg-light">
        <div class="content-container extended">
            <div class="blog-content-container position-relative py-3 mb-3 px-1 px-md-5">
                <p class="h3 fw-bolder text-start mb-2">In Other News</p>
                <pla-featured-blogs></pla-featured-blogs>
            </div>
        </div>
    </div>
</div>