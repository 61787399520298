import { Component, EventEmitter, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Registration } from 'src/app/models/registration';
import { SessionService } from 'src/app/services/session-service';
import { UserService } from 'src/app/services/user-service';

@Component({
  selector: 'pla-search-result-row',
  templateUrl: './search-result-row.component.html',
  styleUrls: ['./search-result-row.component.scss'],
})
export class SearchResultRowComponent implements OnInit {
  @Input('loading') public loading: boolean = false;
  @Input('plate') public plate: Registration;
  @Input('lastRow') public lastRow: boolean = false;
  @Input('showRating') public showRating: boolean = false;
  @Input('whitePlate') public whitePlate: boolean = false;

  @Input('showPrice') public showPrice: boolean = true;
  @Input('showButton1') public showButton1: boolean = true;
  @Input('showButton2') public showButton2: boolean = true;
  @Input('showButton3') public showButton3: boolean = true;
  @Input('customText') public customText: string = null;

  public loggedIn: boolean = true;

  constructor(
    private router: Router,
    private userService: UserService,
    private sessionService: SessionService
  ) {
    this.loggedIn = this.sessionService.isLoggedIn();
  }

  ngOnInit(): void { }

  public gotoCompareLink(reg: Registration): void {
    var url = `/compare/${reg.registration}`;
    this.router.navigate([url]);
  }

  public notifyRegistration(reg: Registration): void {
    reg.notify = !reg.notify; // UI update
    this.userService.notifyRegistration(reg, (notifs: Registration[]) => {
      console.log(notifs);
      var _filteredNotifies = notifs.filter(
        (f) =>
          f.registration.replace(' ', '') == reg.registration.replace(' ', '')
      );
      reg.notify = _filteredNotifies && _filteredNotifies.length > 0;
    });
  }

  public openPlateAuction(plate: Registration) {
    if (plate.meta_data['auction_id'] == null)
      plate.meta_data['auction_id'] = '';
    window.open(
      `https://dvlaauction.co.uk/auction/${plate.meta_data['auction_id']}/${plate.registration}`,
      '_blank'
    );
  }

  public openPlateDetail(registration: Registration) {
    var query =
      registration.search == null
        ? { t: 'checkout' }
        : { s: registration.search, t: 'checkout' };
    this.router.navigate([`/plate-detail/${registration.registration}`], {
      queryParams: query,
    });
  }

  public isPOA(registration: Registration) {
    return registration.price == 0 && registration.plateOwner != 0;
  }
}
