import { Component, EventEmitter, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import {
  InfoDropdown,
  InfoDropdownContent,
} from 'src/app/components/faq/faq.component';
import { FeatureType } from 'src/app/components/featured-plates/featured-plates.component';
import { SidebarFilterConfig } from 'src/app/components/side-bar-filters/side-bar-filters.component';
import {
  StickyConfig,
  StickyLink,
} from 'src/app/components/sticky-tabs/sticky-tabs.component';
import { CompareFilters } from 'src/app/components/tp-sellers/tp-sellers.component';
import { FormattedPlateResponse } from 'src/app/models/formattedPlateResponse';
import { PlateDetail } from 'src/app/models/plateDetail';
import { ImageKeyPair, ImageService } from 'src/app/services/image-service';
import { RegistrationService } from 'src/app/services/registration-service';
import { UserService } from 'src/app/services/user-service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'pla-compare-page',
  templateUrl: './compare-page.component.html',
  styleUrls: ['./compare-page.component.scss'],
})
export class ComparePageComponent implements OnInit {
  public plateToCompare: string = '';
  public newPage: boolean = !environment.production;
  public cards: any[] = [
    { number: 'https://cdn.platex.co.uk/pages/compare/point_01.png', image: 'https://cdn.platex.co.uk/pages/compare/_platex_compare_imac.png?t=123', 'title': 'ENTER THE REGISTRATION', 'body': `To compare prices for a private plate you've already chosen, just enter the exact registration number take care to correctly enter the letters 'O' and 'I' and the numbers '0' and '1'.` },
    { number: 'https://cdn.platex.co.uk/pages/compare/point_02.png', image: 'https://cdn.platex.co.uk/pages/compare/Compare Table.png', 'title': 'FIND OUT HOW MUCH YOU COULD SAVE', 'body': 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur sit amet accumsan justo. Cras laoreet sodales eros eget mattis. Cras felis lorem, dignissim ac erat sit amet, elementum vestibulum felis' },
    { number: 'https://cdn.platex.co.uk/pages/compare/point_03.png', image: 'https://cdn.platex.co.uk/pages/compare/Compare Filter.png', 'title': 'TAILOR YOUR RESULTS ', 'body': 'You can filter dealer prices to include  acrylic plates or managed transfer service. Prices of options vary greatly so filter to find the best overall deal.' },
    { number: 'https://cdn.platex.co.uk/pages/compare/point_04.png', image: 'https://cdn.platex.co.uk/pages/compare/dealer_imac.png', 'title': 'CHECKOUT DIRECTLY WITH THE DEALER', 'body': 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur sit amet accumsan justo. Cras laoreet sodales eros eget mattis. Cras felis lorem, dignissim ac erat sit amet, elementum vestibulum felis' }
  ]

  public stickyLinkConfig: StickyConfig = new StickyConfig(
    [
      new StickyLink('Compare Deals', 'compare-deals', true),
      new StickyLink('History', 'history', false),
      new StickyLink('Market Insights', 'market-insight', false),
      new StickyLink('Q&As', 'faq', false),
    ],
    new EventEmitter<void>()
  );
  public rsFeatured = new FeatureType('Recently Sold', 'recently-sold');
  public raFeatured = new FeatureType('Recently Added', 'recently-added');
  public compareFilters = new EventEmitter<
    SidebarFilterConfig<CompareFilters>
  >();

  public registrationLoaded: boolean = false;
  public registration: string;
  public validRegistration: boolean = false;
  public selectedImage: ImageKeyPair = undefined;

  public faq: InfoDropdown[] = [
    new InfoDropdown(
      'faq_1',
      'Are the prices shown fully inclusive of VAT and shipping?',
      false,
      [
        new InfoDropdownContent(
          null,
          `<p class="fs-md-16 fs-mb-16">While we endeavour to show prices of private plates inclusive of shipping for technical or resource reasons, some personal registration dealer's price may miss shipping cost information or only be included at checkout.</p>
        <p class="fs-md-16 fs-mb-16">In more rare cases some private plate dealer prices may exclude VAT in their website listings. If we are informed that a cherished registration dealer displayed prices without VAT, thus improving their position in price comparison, we will add a note to “check with the private plate dealer” to prompt users to refer to the reg dealer's site yourself to check whether VAT and shipping costs are inclusive.</p>
        <p class="fs-md-16 fs-mb-16">PlateX currently compares the prices of over 67 million million unique private number plates from over 40 private plate dealers, the DVLA and auctions. In some instances, there may only be a single price for a cherished registration, for example for a private sales listing, or if the dealer chooses to be the sole supplier of a private registration.</p>`
        ),
      ]
    ),
    new InfoDropdown(
      'faq_2',
      'How do you compare and sort the prices for private plates?',
      false,
      [
        new InfoDropdownContent(
          null,
          `<p class="fs-md-16 fs-mb-16">PlateX price comparison service displays results from lowest price for the cherished registration number on a paper certificate only, however PlateX users can use the toggle to show the lowest price with a set of standard physical number plates (when offered by the dealer) and/or the popular transfer service where a private plate dealer handles all the required documentation to transfer your new personal registration to your car.</p>`
        ),
      ]
    ),
    new InfoDropdown('faq_3', 'Do you sell registrations yourself?', false, [
      new InfoDropdownContent(
        null,
        `<p class="fs-md-16 fs-mb-16">No PlateX does not own or sell any registrations directly.  PlateX is an information service, price comparison site and listing service.  We act as a portal to the entire personal registration market and our sophisticated search algorithm and search tools help users find their perfect private plate.</p>`
      ),
    ]),
    new InfoDropdown(
      'faq_4',
      'How do you show so many registrations prices?',
      false,
      [
        new InfoDropdownContent(
          null,
          `<p class="fs-md-16 fs-mb-16">As a private plate portal and price comparison site we strive to show you as many private registrations as possible regardless of whether the dealer is a customer of PlateX.</p>
        <p class="fs-md-16 fs-mb-16">Most cherished registration prices are updated automatically while others by our team at least once a day, some several times. However, private plate dealers may update their prices more often and consequently PlateX does not guarantee that prices are correct. Always double-check the price on the personal plate dealer's website before completing a purchase to ensure that the price you saw at PlateX is correct.</p>`
        ),
      ]
    ),
    new InfoDropdown(
      'faq_5',
      'I have noticed an error in prices reported by PlateX can I report it?',
      false,
      [
        new InfoDropdownContent(
          null,
          `<p class="fs-md-16 fs-mb-16">Whilst we endeavour to report number private plate prices accurately due to the vast amount of data and frequent price changes there can be instances where errors occur. Should you find an error with a private plate you can report in here.</p>`
        ),
      ]
    ),
  ];

  public detail: {
    min: number;
    max: number;
    lastUpdate: Date;
    plateDetail: PlateDetail;
    loaded: boolean;
  } = {
      min: 0,
      max: 0,
      lastUpdate: new Date(),
      plateDetail: undefined,
      loaded: false
    };

  constructor(
    private router: Router,
    private imageService: ImageService,
    private activatedroute: ActivatedRoute,
    private registrationService: RegistrationService,
    private userService: UserService,
    private sanitize: DomSanitizer
  ) {
    // check for input
    var reg = this.activatedroute.snapshot.paramMap.get('registration');
    if (reg == null) return;
    this.registration = reg;
    this.formatInput();
  }

  public hasCompareHistory(): boolean {
    if (this.userService.getHistory('compare').length > 0) return true;
    return false;
  }

  public EnterSubmit(event: KeyboardEvent): void {
    if (event.code === 'Enter') {
      event.stopImmediatePropagation();
      this.StartCompare();
    }
  }

  public StartCompare(): void {
    this.router.navigate([`/compare/${this.plateToCompare}`]);
  }

  public SetCompareFilters(
    compareFilters: SidebarFilterConfig<CompareFilters>
  ): void {
    this.compareFilters.emit(compareFilters);
  }

  public CompareResultsChange(firstLoad: boolean): void {
    if (firstLoad) return;
    setTimeout(() => {
      this.stickyLinkConfig.reScroll.emit();
    }, 100);
  }

  public PriceRangeChange(data: { min: number; max: number }): void {
    this.detail = { ...this.detail, min: data.min, max: data.max, loaded: true };
  }

  private formatInput(): void {
    this.registrationLoaded = false;
    this.registrationService
      .FormatRegistration(this.registration)
      .subscribe((formattedResp: FormattedPlateResponse) => {
        this.registrationLoaded = true;
        if (
          formattedResp.formattedRegistration == null ||
          formattedResp.formattedRegistration == ''
        )
          return;
        this.validRegistration = formattedResp.valid;
        this.registration = formattedResp.formattedRegistration;
        this.generateQuickImage();
      });
  }

  ngOnInit(): void {
    this.GetDetail();
  }

  private GetDetail(): void {
    this.registrationService
      .getPlateDetail(this.registration)
      .subscribe((d) => (this.detail = { ...this.detail, plateDetail: d }));
  }

  private generateQuickImage(): void {
    if (this.registration == '') this.registration = 'PLA73X';
    this.imageService.INIT();
    this.imageService.quickBuild(
      this.imageService.getQuickConfig(),
      this.registration,
      (image: ImageKeyPair) => {
        this.selectedImage = image;
      }
    );
  }
}
