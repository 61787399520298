import { Location } from '@angular/common';
import { Component, EventEmitter, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FeatureType } from 'src/app/components/featured-plates/featured-plates.component';
import { SwitchTab } from 'src/app/components/switch-toggle/switch-toggle.component';
import { RegistrationService } from 'src/app/services/registration-service';

@Component({
  selector: 'pla-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss'],
})
export class LandingComponent implements OnInit {
  public tabs: SwitchTab[] = [
    new SwitchTab('BUY', 'buy'),
    // new SwitchTab('COMPARE', 'compare'),
    new SwitchTab('SELL', 'sell'),
  ];
  public activeSwitch: string = 'buy';
  public forceSwitch: EventEmitter<string> = new EventEmitter<string>();

  public xmasFeatured = new FeatureType('🎅🏼 Christmas Plates 🎄', 'x-mas');
  public vDayFeatured = new FeatureType(
    '❤ Valentines Plates 😘',
    'valentines-2024'
  );
  public raFeatured = new FeatureType('Recently Added', 'recently-added');
  public rsFeatured = new FeatureType('Recently Sold', 'recently-sold');
  public plateCount: number = Math.floor(
    Math.random() * (6820000 - 6810000 + 1) + 68000000
  );

  constructor(private registrationService: RegistrationService, private router: Router, private route: ActivatedRoute, private location: Location) {
    if (this.router.url === '/buy') this.activeSwitch = 'buy';
    if (this.router.url === '/sell') this.activeSwitch = 'sell';
    const pageType = this.route.snapshot.paramMap.get('type');
    if (pageType !== null) {
      if (pageType === 'buy') this.activeSwitch = 'buy';
      if (pageType === 'sell') this.activeSwitch = 'sell';
    }

    this.registrationService
      .getPlateCount()
      .subscribe((_: { totalCount: number }) => {
        this.plateCount = _.totalCount;
      });
  }

  public SwitchChange(tab: SwitchTab): void {
    this.activeSwitch = tab.id;
    this.location.go(`/${tab.id}`);
  }

  ngOnInit(): void {
  }
}

