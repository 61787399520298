<div class="page">
  <div class="content-container extended">
    <div class="w-100 position-relative">
      <div class="position-relative">
        <pla-banner-image [carBanner]="activeSwitch === 'buy' ? 'LANDING_918' : 'SELL_992_TURBO'"
          [mobileCarBanner]="activeSwitch === 'buy' ? 'LANDING_918_MB' : 'SELL_992_TURBO_MB'" [rawImage]="true"
          [backgroundImage]="true" [backgroundClass]="'desktop-mobile-background'">
          <div
            class="d-flex align-items-stretch justify-content-start flex-column px-2 py-3 p-md-4 landing-banner-height"
            style="background-color: rgba(13,13,13,0.14);">

            <div class="d-none d-md-flex align-items-stretch justify-content-start flex-column">
              <!-- HEADER TEXT -->
              <div class="text-start w-100 align-self-start justify-content-start">
                <p class="fs-md-45 fs-mb-35 mb-1 lh-1 fw-bold text-white">
                  {{ activeSwitch === 'buy' ? 'The Market in One Plate' : 'LIST. SELL. GET PAID' }}
                </p>
                <p *ngIf="activeSwitch === 'buy'" class="fw-light fs-md-18 fs-mb-15 mt-2 text-white">
                  {{ plateCount | number }} Private Plates Available
                </p>

                <p *ngIf="activeSwitch === 'sell'" class="fw-light fs-md-18 fs-mb-15 mt-2 text-white">Sell direct and
                  save £££ on commission and fees</p>


                <div class="mt-4 d-block" style="min-width: 150px; max-width: 200px;">
                  <pla-switch-toggle [selectedTabId]="activeSwitch" [tabs]="tabs" [chipColour]="'#ffffff14'"
                    [pillRadius]="2" [chipTextColour]="'#ffffff'" [selectedChipColour]="'#ffffffdb'"
                    [selectedChipTextColour]="'#3A5B64'" [forceSwitch]="forceSwitch"
                    (switchChange)="SwitchChange($event)"></pla-switch-toggle>
                </div>
              </div>
            </div>

            <div class="flex-fill d-flex d-md-none align-items-stretch justify-content-between flex-column">
              <div class="pt-3 text-start w-100 align-self-start justify-content-start">
                <div style="min-height: 110px;" class="d-flex flex-column align-items-end justify-content-center">
                  <ng-container *ngIf="activeSwitch === 'sell'">
                    <p style="letter-spacing: -0.5px !important;"
                      class="fs-md-45 fs-mb-32 mb-2 lh-1 fw-bolder text-white d-block w-100 text-center">LIST. SELL.
                      GET
                      PAID</p>
                    <p class="fs-md-20 fs-mb-17 mb-4 lh-1 fw-normal text-white d-block w-100 text-center">FREE and
                      INSTANT
                      valuation</p>
                  </ng-container>

                  <ng-container *ngIf="activeSwitch === 'buy'">
                    <p class="fs-md-45 fs-mb-30 mb-2 lh-1 fw-bold text-white d-block w-100 text-center">SEARCH
                      ANYTHING.<br />FIND EVERYTHING</p>
                    <p class="fs-md-45 fs-mb-17 mb-4 lh-1 fw-bold text-white d-block w-100 text-center">COMPARE and SAVE
                    </p>
                  </ng-container>
                </div>

                <div class="d-block mx-auto" style="min-width: 150px; max-width: 200px;">
                  <div class="border border-1 border-white">
                    <pla-switch-toggle [selectedTabId]="activeSwitch" [tabs]="tabs" [chipColour]="'#ffffff14'"
                      [pillRadius]="2" [chipTextColour]="'#ffffff'" [selectedChipColour]="'#ffffffdb'"
                      [selectedChipTextColour]="'#3A5B64'" [forceSwitch]="forceSwitch"
                      (switchChange)="SwitchChange($event)"></pla-switch-toggle>
                  </div>
                </div>
              </div>


              <div class="w-100 flex-fill d-flex flex-column justify-content-end align-items-stretch px-3">
                <p *ngIf="activeSwitch === 'sell'"
                  class="fw-normal fs-md-18 fs-mb-14 lh-sm my-2 d-block w-100 text-center text-white">
                  Harness the power of the UK's largest registration database for an evidence driven estimate!
                </p>

                <p *ngIf="activeSwitch === 'buy'"
                  class="fw-normal fs-md-18 fs-mb-14 lh-sm my-2 d-block w-100 text-center text-white">
                  The whole market at your fingertips<br />{{ plateCount | number }} Private Plates Available
                </p>
              </div>

            </div>


          </div>
        </pla-banner-image>
      </div>
    </div>
  </div>

  <div>

    <!-- PAGE BANNER CONTENT DESKTOP -->
    <div class="d-none d-md-block w-100 pb-4" style="background: #FFF;" *ngIf="activeSwitch === 'buy'">
      <div class="position-relative mx-auto px-5" style="max-width: 550px; height: 160px;">

        <!-- BUY BANNER BLOCK -->
        <div *ngIf="activeSwitch === 'buy'"
          class="position-absolute top-0 start-0 end-0 mt-3 p-4 border-white border border-2"
          style="background-color: #214953; transform: translateY(-50%);">
          <p class="fw-normal fs-md-22 fs-mb-15 mb-2 text-white text-center">Search anything, Find everything</p>
          <p class="fw-light fs-md-16 fs-mb-16 mb-2 text-white text-center">Search all private plates for sale in the UK
          </p>

          <div class="py-3">
            <div
              class="mx-auto d-flex align-items-stretch justify-content-between border border-2 rounded-2 overflow-hidden"
              style="border-color: #327B8D !important; background-color: #327B8D; width: fit-content;">
              <div
                class="plate white plate-style-important d-flex py-2 align-items-stretch justify-content-center shadow-none"
                style="border-radius: 4px 0 0 4px;">
                <input class="fs-md-18 fsm-mb-16 p-0" style="background: none; max-width: 240px;"
                  placeholder="What can we find for you?" />
              </div>
              <button style="background-color: #327B8D;"
                class="fs-md-12 fs-mb-12 btn text-white d-flex align-items-center justify-content-center px-2 rounded-0 gap-1">
                <mat-icon class="">search</mat-icon>
              </button>
            </div>
          </div>

          <div class="w-100 d-flex align-items-center justify-content-center gap-2 flex-wrap mx-auto"
            style="max-width: 400px;">
            <a style="background-color: #1D1D1D;" [routerLink]="'/dateless-search'"
              class="text-decoration-none border border-secondary rounded-3 text-white px-2 py-1 fs-md-18 fs-mb-16">Dateless</a>
            <a style="background-color: #1D1D1D;" [routerLink]="'/initials-search'"
              class="text-decoration-none border border-secondary rounded-3 text-white px-2 py-1 fs-md-18 fs-mb-16">Initials</a>
            <a style="background-color: #1D1D1D;" [routerLink]="'/byo-search'"
              class="text-decoration-none border border-secondary rounded-3 text-white px-2 py-1 fs-md-18 fs-mb-16">Build-Your-Own</a>
          </div>
        </div>

        <!-- SELL BANNER BLOCK -->
        <div *ngIf="false && activeSwitch === 'sell'"
          class="position-absolute top-0 start-0 end-0 mt-3 p-4 border-white border border-2"
          style="background-color: #214953; transform: translateY(-50%);">
          <p class="fs-md-16 fs-mb-16 fw-light mb-4 text-white">Harness the power of the UK's
            largest registration database for an evidence driven FREE and INSTANT valuation
            estimate!
          </p>

          <div>
            <!-- PLATE -->
            <div class="d-flex align-items-center justify-content-between gap-4 mb-3">
              <div>
                <small class="text-nowrap text-white">Enter your Registration Number</small>
              </div>
              <div class="input-container plate-background" style="max-width: 160px;">
                <input class="plate-font" type="text" spellcheck="false" autocomplete="false" placeholder="112 JAY" />
              </div>
            </div>

            <!-- TERM -->
            <div class="d-flex align-items-center justify-content-between gap-4">
              <div>
                <small class="text-nowrap text-white">Enter the Word / initials that best represent it.</small>
              </div>
              <div class="input-container plate-background white" style="max-width: 160px;">
                <input class="plate-font" type="text" spellcheck="false" autocomplete="false" placeholder="JAY" />
              </div>
            </div>
          </div>

          <div class="mt-4 ms-0 me-auto d-flex align-items-stretch justify-content-between rounded-2 overflow-hidden">
            <button style="background-color: transparent;"
              class="fs-md-12 fs-mb-12 btn d-flex align-items-center justify-content-start gap-1 p-0">
              <span style="color: #FACF15" class="fs-md-18 fsm-mb-16 p-0">Value Your
                Plate</span>
              <mat-icon style="color: #FACF15;" class="">chevron_right</mat-icon>
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- PAGE CONTENT -->
    <div class="">
      <pla-buy-landing *ngIf="activeSwitch === 'buy'"></pla-buy-landing>
      <pla-sell-landing *ngIf="activeSwitch === 'sell'"></pla-sell-landing>

      <div>
        <div class="content-container extended">
          <div class="container-fluid w-100">
            <div class="row px-0">
              <p class="px-4 fs-md-26 fs-mb-24 lh-sm fw-bolder text-start h-mb">Plates Sold - Live Feed</p>
            </div>

            <div class="row">
              <pla-price-history-chart></pla-price-history-chart>
            </div>
          </div>
        </div>

        <div class="s-spacing"></div>
      </div>
    </div>
  </div>
</div>