import { Component, Input, OnInit } from '@angular/core';
import { TabbedSection } from 'src/app/components/tabbed-section/tabbed-section.component';
import { PlateSeller } from 'src/app/models/thirdPartySeller';
import { CompareService } from 'src/app/services/compare-service';

export class SellerTableRow {
  constructor(
    public title: string,
    public cert: boolean,
    public transfer: boolean,
    public plate: boolean,
    public seller: PlateSeller
  ) {}
}

@Component({
  selector: 'pla-seller-table',
  templateUrl: './seller-table.component.html',
  styleUrls: ['./seller-table.component.scss'],
})
export class SellerTableComponent implements OnInit {
  @Input() public sellers: PlateSeller[] = [];
  public sellerRows: SellerTableRow[] = [];
  public tabbedSellerRow: SellerTableRow = null;

  public formattedSellers: PlateSeller[] = [];
  public selectedTab: TabbedSection = new TabbedSection('Certificate', 'cert');
  public tabs: TabbedSection[] = [
    new TabbedSection('Certificate', 'cert'),
    new TabbedSection('+ Plates', 'cert_plate'),
    new TabbedSection('+ Transfer', 'cert_transfer'),
    new TabbedSection('All Inclusive', 'cert_plate_transfer'),
  ];

  constructor(private compareService: CompareService) {}

  ngOnInit(): void {
    this.formatSellers();
  }

  public selectionChangeHandler(tab: TabbedSection): void {
    this.getSellerRowForTab(
      tab.tab_id.indexOf('_plate') > -1,
      tab.tab_id.indexOf('_transfer') > -1
    );
  }

  private getSellerRowForTab(plate: boolean, transfer: boolean) {
    this.tabbedSellerRow = this.sellerRows.filter(
      (sr: SellerTableRow) => sr.plate == plate && sr.transfer == transfer
    )[0];
  }

  private formatSellers(): void {
    var valid_sellers = this.sellers.filter((s) => s.totalPrice > 0);
    if (valid_sellers == null) return;
    this.formattedSellers = valid_sellers.sort(
      (a, b) => a.totalPrice - b.totalPrice
    );

    var noOptions = this.compareService.calculateSellerRows(
      this.formattedSellers,
      false,
      false
    );
    var incPlate = this.compareService.calculateSellerRows(
      this.formattedSellers,
      false,
      true
    );
    var incTransfer = this.compareService.calculateSellerRows(
      this.formattedSellers,
      true,
      false
    );
    var allOptions = this.compareService.calculateSellerRows(
      this.formattedSellers,
      true,
      true
    );

    var topNoOption = noOptions.sort((a, b) => a.totalPrice - b.totalPrice)[0];
    var topIncPlate = incPlate.sort((a, b) => a.totalPrice - b.totalPrice)[0];
    var topIncTransfer = incTransfer.sort(
      (a, b) => a.totalPrice - b.totalPrice
    )[0];
    var topAllOptions = allOptions.sort(
      (a, b) => a.totalPrice - b.totalPrice
    )[0];

    this.sellerRows = [
      new SellerTableRow('Certificate Only', true, false, false, topNoOption),
      new SellerTableRow(
        'Certificate + Perspex Number Plates',
        true,
        false,
        true,
        topIncPlate
      ),
      new SellerTableRow(
        'Certificate + Transfer Service',
        true,
        true,
        false,
        topIncTransfer
      ),
      new SellerTableRow(
        'Certificate + Perspex Plates + Transfer Service',
        true,
        true,
        true,
        topAllOptions
      ),
    ];
    this.getSellerRowForTab(
      this.selectedTab.tab_id.indexOf('_plate') > -1,
      this.selectedTab.tab_id.indexOf('_transfer') > -1
    );

    // calculate all sellers with :-
    // just cert
    // cert + plate
    // cert + transfer
    // cert + transfer + plate
  }

  private generatePricing(physical: boolean, transfer: boolean): void {}

  public sellerHasCert(seller: PlateSeller): boolean {
    return true; //  seller always has cert
  }

  public sellerHasPhysicalPlate(seller: PlateSeller): boolean {
    return seller.physicalPlatePrice > 0;
  }

  public sellerHasTransferService(seller: PlateSeller): boolean {
    return seller.transferService > 0;
  }
}
